import {Modal, FlexboxGrid } from "rsuite";
import {useTranslation} from "react-i18next";
import Button from "./button";
import {useEffect, useState} from "react";
import i18n from 'i18next'

const DTCRestrictModal = ({ open, onClose }) => {
  const [modalOpen, setModalOpen] = useState(open)
  const { t } = useTranslation()

  useEffect(() => {
    setModalOpen(open)
  }, [open])

  console.log('language', i18n.language, t('hi'))

  return (
    <Modal backdrop='static' role='alertdialog' open={modalOpen} onClose={() => {
      setModalOpen(false)
      onClose && onClose()
    }} size='md'>
      <Modal.Header>
        <b>{t`Single Code / Symptom Selection Required`}</b>
      </Modal.Header>
      <Modal.Body>
        {t`1 dtc`}
      </Modal.Body>
      <Modal.Footer>
        <FlexboxGrid style={{ marginTop: '20px' }} justify='center'>
          <FlexboxGrid.Item colspan={10}>
            <Button width='100%' onClick={async () => {
              setModalOpen(false)
              onClose && onClose()
              }}
            >
              {t`Ok`}
            </Button>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Modal.Footer>
    </Modal>
  )
}

export default DTCRestrictModal
