import {useState, useMemo } from 'react'
import PQueue from 'p-queue'
import * as R from 'ramda'
import shuffle from 'lodash.shuffle'
import useDiagnostic from './useDiagnostic'
import { useTranslation } from 'react-i18next'

import { sortPcs } from '../utils/sorthelper'

import { image, imageWithoutToken, ssdbImage } from '../utils/fetcher'
import { useSelector } from 'react-redux'
import {
  buildTree,
  filterTree, findElementById,
  findElementsWithPcId,
  makeTreeWithFlattenChildren, removeDuplicates,
  toDictionary,
  traverseAndModify
} from '../utils'
import {isNil} from "ramda";
import { selectQueryParams, selectSignalMapping } from '../store/application'

let cache = {}
// @ts-ignore
export const getAllData = () => cache
// @ts-ignore
export const getDataByKey = (key: string, series?: string) => {
  if (cache && series) {
    return cache?.[`${series.toLowerCase()}_${key}`] || cache?.[key] || []
  } else {
    return cache?.[key] || []
  }
}

const useProductData = () => {
  const { selectedSeries, selectedProduct, selectedDiagnosticItems, sortWeights, testAnswers } = useDiagnostic()
  const [allData, setData] = useState({})

  const user = useSelector(state => state.user?.currentUser)
  const signalMapping = useSelector(selectSignalMapping)
  const queryParams = useSelector(selectQueryParams)

  const { i18n } = useTranslation()

  const _getDataByKey = (key: string) => {
    const series = selectedSeries?.label
    // console.log('useProductData :: _getDataByKey', key, series, cache)
    if (cache && series) {
      return cache?.[`${series.toLowerCase()}_${key}`] || cache?.[key] || []
    } else {
      return cache?.[key] || []
    }
  }
  const localisationResourceTranslated = useMemo(() => toDictionary(_getDataByKey(`localisation_resource_${i18n.language}`) || [], 'id'), [i18n.language])
  const localisationResourceDefault = useMemo(() => toDictionary(_getDataByKey('localisation_resource'), 'id'), [])
  const getDefaultLocalisation = (objectToTranslate: any, fields: string[]) => fields.map(field => {
    if (!objectToTranslate) return  {}
    console.log('getDefaultLocalisation', field, 'current language', i18n.language, objectToTranslate)
    let result = localisationResourceTranslated?.[objectToTranslate?.[field]]?.defaultValue
    console.log('result', result)
    if (!result || result.length === 0) result = localisationResourceDefault?.[objectToTranslate?.[field]]?.defaultValue
    return {
      [`${field}Translation`]: result
    }
  })

  const diagnosticItemsByMappingGroup = useMemo(() => {
    if (!selectedProduct?.value) return []

    const diagnostic_items = _getDataByKey('diagnostic_item')

    return diagnostic_items.filter(
      diagnosticItem =>
        diagnosticItem.mappingGroupId === selectedProduct.item.mappingGroupId && diagnosticItem.searchable !== 0
    )
  }, [selectedProduct?.value])

  const displayModelSet = _getDataByKey('display_model_set').find(_ => _.id === selectedProduct?.value)

  const pcDetailByMappingGroup = useMemo(() => {
    if (!selectedProduct?.value) return []
    return _getDataByKey('pc_detail').filter(pcd => pcd.mappingGroupId === selectedProduct?.item.mappingGroupId)
  }, [selectedProduct?.value])

  const getPcDetail = (pcId: number, pg: number) => {
    const map_pc_detail_pg = _getDataByKey('map_pc_detail_pg')

    const pcDetailsWithProductGroups = pcDetailByMappingGroup
      .filter(pcd => pcd.pcId === pcId)
      .map(pcD => ({
        ...pcD,
        productGroups: map_pc_detail_pg.filter(pcdPg => pcdPg.pcDetailId === pcD.id && pcdPg.productGroupId === pg)
      }))

    if (pcDetailsWithProductGroups.length === 0) {
      return {}
    }

    const pcDetailWithSpecificPG = pcDetailsWithProductGroups.find(pcd => pcd.productGroups.some(pcdPg => pcdPg.productGroupId === pg))

    if (pcDetailWithSpecificPG) {
      return pcDetailWithSpecificPG
    }

    return pcDetailsWithProductGroups.find(pcd => pcd.default) || {}
  }

  const productGroupIds = (displayModelSet?.productGroups || []).map(_ => _?.id)
  const mapDiagnosticItem = (diagnosticItem, smartdiag_dtc, mapSdDtcs, original_dtc, symptoms, diagnostic_items_pc, withPc) => {
    const { id, dtcId, symptomId, pcId } = diagnosticItem

    diagnosticItem.hasPc = diagnostic_items_pc.some(sd => sd.parent === id)

    if (!withPc && pcId) {
      // console.log('!withPc && pcId', diagnosticItem)
      return null
    }

    if (dtcId) {
      diagnosticItem.dtc = smartdiag_dtc[dtcId]
      const originalDtcId = mapSdDtcs[dtcId]?.originalDtcId
      diagnosticItem.originalDtc = original_dtc[originalDtcId]
      diagnosticItem.originalDtcId = originalDtcId
      diagnosticItem.pCode = diagnosticItem?.originalDtc?.pCode
      diagnosticItem.label = diagnosticItem?.dtc?.name
      diagnosticItem.value = originalDtcId
    }

    if (symptomId) {
      const symptom = symptoms[symptomId]
      if (!symptom) return diagnosticItem

      diagnosticItem.symptom = {
        ...symptom,
        ...R.mergeAll(getDefaultLocalisation(symptom, ['nameResourceId']))
      }

      diagnosticItem.value = symptomId
      diagnosticItem.label = diagnosticItem.symptom.nameResourceIdTranslation
    }

    return diagnosticItem
  }

  // @ts-ignore
  const diagnosticItemsBySelectedSeries = useMemo( () => {
    if (!selectedProduct?.value) return []

    try {
      console.log('diagnosticItemsBySelectedSeries')
      const diagnostic_items_pc = diagnosticItemsByMappingGroup.filter(di => !!di.pcId)
      const smartdiag_dtc = toDictionary(_getDataByKey('smart_diag_dtc'), 'id')
      const symptom = toDictionary(_getDataByKey('symptom'), 'id')
      const original_dtc = toDictionary(_getDataByKey('original_dtc'), 'id')
      const mapSdDtcs = toDictionary(_getDataByKey('map_sddtc_dtc'), 'smartDiagDtcId')
      const diagnosticItemsMapped = diagnosticItemsByMappingGroup
        .map(diagnosticItem => mapDiagnosticItem(diagnosticItem, smartdiag_dtc, mapSdDtcs, original_dtc, symptom, diagnostic_items_pc, false))
        .filter(v => !!v)

      console.log('diagnosticItemsByMappingGroup', selectedProduct.item.mappingGroupId, diagnosticItemsByMappingGroup)
      console.log('diagnostic_items_pc', diagnostic_items_pc)

      console.log('buildTree(makeTreeWithFlattenChildren(diagnosticItemsMapped)) ', 'diagnosticItemsMapped', diagnosticItemsMapped.length, buildTree(diagnosticItemsMapped))
      return R.sortBy(R.prop('label'), makeTreeWithFlattenChildren(buildTree(diagnosticItemsMapped)))
    } catch (e) {
      console.error(e)
    }
  }, [selectedProduct?.value, i18n.language])

  const selectedDiagnosticItemsIds = selectedDiagnosticItems?.map((d: any) => d.id)

  const getDtcDetails = (dtcId: number) => {
    console.log('getDtcDetails', dtcId)
    // const map = _getDataByKey('map_sddtc_dtc').find(map => map.smartDiagDtcId === dtcId)
    const map = _getDataByKey('map_sddtc_dtc').find(map => map.originalDtcId === dtcId)
    console.log('getDtcDetails', map)
    if (!map) return
    const origDtc =  R.reject(R.anyPass([R.isEmpty, R.isNil]))(_getDataByKey('original_dtc').find(originalDtc => map.originalDtcId === originalDtc.id))

    return ({
      ...R.omit(
        [...Object.keys(origDtc).filter(k => R.endsWith('ResourceId', k)), 'createdAt', 'updatedAt'],
        origDtc
      ),
      ...R.mergeAll(
        getDefaultLocalisation(
          origDtc,
          Object.keys(origDtc).filter(orDtc => orDtc.endsWith('ResourceId'))
        )
      )
    })
  }

  const getDtcsWithSamePCs = (pcIds: number[]) => map_dtc_pc.filter((map: any) => pcIds.includes(map.pcId)).map((map: any) => map.smartDiagDtcId)

  const getRelatedDTCs = (dtscIds: number[]) => {
    return []
    if (!dtscIds.length) return []
    const maxSuggestedDtc = 4
    const fieldsToSearch = ['smartDiagDtc1Id', 'smartDiagDtc2Id', 'smartDiagDtc3Id', 'smartDiagDtc4Id', 'smartDiagDtc5Id']
    const dtcOccurrence = _getDataByKey('dtc_cooccurrence')
      .filter((dtcOccurrence: any) => fieldsToSearch.some((fieldName: string) => dtscIds.includes(dtcOccurrence[fieldName])))

    const dtcRelatedByDtcOccurrence = R.uniqBy(R.prop('smartDiagDtcTargetId'), dtcOccurrence)
      .sort((a, b) =>  a.confidence - b.confidence)
      .map((dtcOccurrence: any) => {
        const dtc = diagnosticItemsBySelectedSeries.find((dtc: any) => dtc.id === dtcOccurrence.smartDiagDtcTargetId)
        if (dtc) { // in case occurence is set to non-existing DTC
          return {
            ...dtc,
            name: dtc.name // dtc.pCode ? `${dtc.name} P(${dtc.pCode})` : dtc.name SMARTDIAG-18
          }
        }
      })
      .filter(dtc => dtc) // filter out null

    if (dtcRelatedByDtcOccurrence.length < 4) {
      const samePCsIds = (dtscIds, true).map((pc: any) => pc.id)
      const dtcIdsBySharedPc = getDtcsWithSamePCs(samePCsIds)

      const hydratedDTCs = diagnosticItemsBySelectedSeries
        .filter((dtc: any) => !selectedDiagnosticItemsIds.includes(dtc.id))
        .filter((dtc: any) => dtcIdsBySharedPc.includes(dtc.id))

      return [...dtcRelatedByDtcOccurrence, ...shuffle(hydratedDTCs).slice(0, maxSuggestedDtc - dtcRelatedByDtcOccurrence.length)]
    } else {
      return dtcRelatedByDtcOccurrence.slice(0, maxSuggestedDtc)
    }
  }

  const pc = _getDataByKey('pc')
  // const currentLanguage = localStorage.getItem('locale') || 'en-US'
  // const localisationResourceTranslated = _getDataByKey(`localisation_resource_${currentLanguage}`) || []
  /* console.log('cache', cache)
  console.log('i18n language', i18n.language)
  console.log('localisationResourceDefault', localisationResourceDefault)
  console.log('localisationResourceTranslated', localisationResourceTranslated) */
  // const localisationResourceEn = _getDataByKey('localisation_resource_en-US')
  const question = _getDataByKey('question')
  const map_pc_link = _getDataByKey('map_pc_link')
  const link_detail = _getDataByKey('link_detail')
  const map_link_detail_pg = _getDataByKey('map_link_detail_pg')

  /* console.log('localisationResourceDefault', localisationResourceDefault.filter((_: any) => _.groupId !== 22))
  console.log('localisationResource', localisationResource.filter((_: any) => _.groupId !== 22))
  console.log('localisationResourceEn', localisationResourceEn.filter((_: any) => _.groupId !== 22)) */

  const getDtcXCUs = () => R.uniqBy(R.prop('xcu'), _getDataByKey('smart_diag_dtc'))
  const getPCs = () => {
    /* console.log('getPCS', pc)
    console.log('cache', cache) */
    return pc.map(pc =>
      ({
        ...pc,
        ...R.mergeAll(getDefaultLocalisation(pc, [
          'concernedPartResourceId',
          'typeOfFailureResourceId',
        ]))
      })
    )
  }



  const diagnosticItemsBySelectedSeriesTreeDic = useMemo(() => {
    if (!selectedProduct?.value) return []
    return toDictionary(buildTree(diagnosticItemsByMappingGroup), 'id')
  }, [selectedProduct?.value])

  const showPc = (diagnosticItem, diagnosticItems) => {
      const multiDtcSymptoms = map_diagnostic_item_multi.filter(_ => _.diagnosticItemId === diagnosticItem.id)

      if (diagnosticItem.multi && !multiDtcSymptoms.length) {
        return diagnosticItems.length > 1
      }
      if (diagnosticItem.multi && multiDtcSymptoms.length) {
        return multiDtcSymptoms.some(_ => {
          let found = false

          diagnosticItems.forEach(diBranch => {
            traverseAndModify(diBranch, node => {
              if (!found) {
                found = (!isNil(_.dtcId) && _.dtcId === node.dtcId) || (!isNil(_.symptomId) && _.symptomId === node.symptomId)
              }
            })
          })

          return found
        })
      }
      return true
  }
  const map_dtc_pc = _getDataByKey('map_dtc_pc')
  const map_diagnostic_item_multi = _getDataByKey('map_diagnostic_item_multi')

  const diagnosticItemsTreeWithPc = useMemo( () => {
    if (!selectedDiagnosticItems.length) return []
    const childGroupedByRoot = R.groupBy(R.prop('rootId'), selectedDiagnosticItems)
    const pc = toDictionary(_getDataByKey('pc'), 'id')
    const findAndMapPc = pcId => {
      const pcFound = pc[pcId]
      if (!pcFound) {
        console.log('no possibleCauseFound', pcId, pc)
        return {}
      }

      const translatedPc = {
        ...pcFound,
        ...R.mergeAll(
          getDefaultLocalisation(
            pcFound,
            [
              'descriptionResourceId',
              'possibleCauseResourceId',
              'concernedPartResourceId',
              'typeOfFailureResourceId',
              'repairInstructionResourceId'
            ]
          )
        )
      }

      console.log('translatedPc', translatedPc)

      return {
        ...translatedPc,
        label: translatedPc.concernedPartResourceIdTranslation
      }
    }

    const transformTree = (sdi, diTreeNode, sdiArray) => {
      const processNode = (node) => {
        if (node) {
          if (node.pcId && showPc(node, sdiArray)) {
            node.pc = findAndMapPc(node.pcId)
            node.pcDetail = getPcDetail(node.pcId, selectedProduct.value)
          } else {
            delete node.pcId
          }
          node.rootId = sdi.id
          node.sdi = sdi
        }
      }

      if (!diTreeNode) return sdi

      let treeToProcess = diTreeNode

      if (sdi.rootId) {
        const childIds = R.pluck('id', childGroupedByRoot[sdi.rootId])
        const conditionFn = node => childIds.includes(node['id'])
        treeToProcess = filterTree(diTreeNode, conditionFn)
      }

      traverseAndModify(treeToProcess, processNode)

      return treeToProcess
    }

    return selectedDiagnosticItems.map((sdi) => {
      const diTreeNode = R.clone(diagnosticItemsBySelectedSeriesTreeDic[sdi.rootId || sdi.id])
      return transformTree(sdi, diTreeNode, selectedDiagnosticItems)
    })
  }, [JSON.stringify(selectedDiagnosticItems)])

  const getPcListByDiagnosticItems = (rootId = null, diagnosticItemId = null) => {
    if (!diagnosticItemId) {
      return processPcList(diagnosticItemsTreeWithPc, null)
    } else {
      const node = diagnosticItemsTreeWithPc.find(pc => pc.rootId === rootId)
      const childNode = findElementById(node, diagnosticItemId).children
      return processPcList(childNode, testAnswers)
    }
  }

  const processPcList = (nodes, answers) => {
    const pcTree = nodes.map(di => findElementsWithPcId(di)).flat()
    console.log('pcTree', pcTree)
    const filterByPcDetail = node => (node.children?.length > 0) || node.pcDetail?.id
    const filteredPcTree = pcTree.map(tree => filterTree(tree, filterByPcDetail)).filter(t => !!t)
    console.log('pcTree filteredPcTree', filteredPcTree)
    const sortedPcs = sortPcs(sortWeights, filteredPcTree, answers, [])
    return removeDuplicates(sortedPcs)
  }

  const getQuestionsForPc = (pcId: number) => _getDataByKey('map_pc_question')
    .filter((map: any) => map.pcId == pcId)
    .map((map: any) => {
      const questionFound = question.find(q => q.id === map.questionId)
      return {
        pcId: map.pcId,
        ...questionFound,
        ...R.mergeAll(
          getDefaultLocalisation(
            questionFound,
            [
              'questionResourceId',
              'passOutcomeLabelResourceId',
              'failOutcomeLabelResourceId',
            ]
          )
        )
      }
    })

  const getFilesIdsByPcDetail = (pcDetailId, type = 'image') => {
    const map_pc_detail_file =  _getDataByKey('map_pc_detail_file')
    const mapFiles = map_pc_detail_file.filter(mapPcFile => mapPcFile.pcDetailId === pcDetailId)
    const mapTypeFilesIds = mapFiles.filter(mapFile => mapFile.type === type).map(_ => _.fileId)

    return mapTypeFilesIds
  }

  const getSignalsByPcDetail = (pcDetailId) => {
    const map_pc_detail_signal =  _getDataByKey('map_pc_detail_signal')
    const mapSignals = map_pc_detail_signal.filter(mapPcSignal => mapPcSignal.pcDetailId === pcDetailId)
    const signalIds = mapSignals.map(_ => _.signalId)
    const signals = _getDataByKey('signal')
    const signalsFiltered = signals.filter(s => signalIds.includes(s.id)).map(s => s.name)

    console.log('signalsFiltered', signalsFiltered)
    console.log('signalMapping', signalMapping)

    const queryParams = new URLSearchParams(window.location.search)
    const isKobd = (queryParams.get('kobd') === '1') || (queryParams.get('kobd') === 'true') || (queryParams.get('restore') === 'true')  || (queryParams.get('restore') === '1')

    const model = queryParams.get('kobdModel') || 'All'

    if (isKobd) {
      const signalMappingFiltered = signalMapping.filter(sm => sm['KOBD_ACE_Model'] === model && signalsFiltered.includes(sm['Signal_List']))
      console.log('signalMapping', signalMappingFiltered)
      const dataMonitor = R.uniq(signalMappingFiltered.filter(s => s.Function === 'DataMonitor'))
      const launch = R.uniq(signalMappingFiltered.filter(s => s.Function !== 'DataMonitor'))
      console.log('signalMapping', dataMonitor, launch)
      return {
        dataMonitor,
        launch
      }
      // const signalListFiltered = signalMappingFiltered.map(sm => sm.KOB)
    } else {
      return {
        dataMonitor: [],
        launch: []
      }
    }

  }

  const getTestForPc = (pcId: number) => {
    const map_pc_detail_file =  _getDataByKey('map_pc_detail_file')
    const pcDetail = getPcDetail(pcId, selectedProduct.value)
    const test = _getDataByKey('test')
    const tests = test.filter(t => pcDetail?.testId === t.id)
    // const map_test_link = _getDataByKey('map_test_link')
    console.log('currentPcDetail', pcDetail)
    console.log('map_pc_detail_file', map_pc_detail_file)

    return tests.map((t: any) => {
      const imagesIds = getFilesIdsByPcDetail(pcDetail.id)
      const grsImg = getFilesIdsByPcDetail(pcDetail.id, 'grs')
      const signals = getSignalsByPcDetail(pcDetail.id)
      console.log('grsIds', grsImg)


      return {
        pcId,
        signals,
        linksImg: imagesIds,
        grsImg: grsImg,
        ssdbInfo: getSSDBInfoByTestId(t.id, R.pick(['connectorBoth', 'connectorHarness', 'connectorPart', 'arLink'], pcDetail)),
        additionalInfo: getDefaultLocalisation(pcDetail, ['additionalInfoResourceId'])?.[0]?.additionalInfoResourceIdTranslation,
        pcDetailForTest: pcDetail,
        ...t,
        ...R.mergeAll(
          getDefaultLocalisation(
            t,
            [
              'concernedPartResourceId',
              'testQuestionResourceId',
              'testDescriptionResourceId',
              'passOutcomeLabelResourceId',
              'failOutcomeLabelResourceId'
            ]
          )
        )
      }
    }).sort((a, b) => a.priority - b.priority)
  }
  const getRepairInfoByPcId = (pcId: number) => {
    const linksIds = map_pc_link.filter((m: any) => m.pcId == pcId).map((m: any) => m.linkId)
    const productGroupsPrefixes = _getDataByKey('prefix').filter(_ => productGroupIds.includes(_.productGroupId))

    console.log('productGroupsPrefixes', productGroupsPrefixes)
    const wsmPrefix = productGroupsPrefixes && productGroupsPrefixes.length > 0 ? productGroupsPrefixes[0].prefixOfWsmLink : ''
    const diagramPrefix = productGroupsPrefixes && productGroupsPrefixes.length > 0 ? productGroupsPrefixes[0].prefixOfDiagramLink : ''

    const links = link_detail.filter((l: any) => {
      if (linksIds.includes(l.linkId)) {
        const linkProductGroupIds = map_link_detail_pg.filter(map => map.linkDetailId === l.id).map(_ => _.productGroupId)
        return linkProductGroupIds.find(_ => productGroupIds.includes(_.id))
      }
    }).map((ld:any) => {
      const linkDetail = _getDataByKey('link').find((l: any) => l.id === ld.linkId)
      const linkName = linkDetail?.name
      const wsmGuid = _getDataByKey('guid').find((g: any) => g.id === ld.repairWsmId)?.guid
      const diagramGuid = _getDataByKey('guid').find((g: any) => g.id === ld.circuitDiagramId)?.guid
      const wsmLink = wsmPrefix && wsmGuid ? wsmPrefix + '/' + wsmGuid + '.html?token=' + localStorage.getItem('smartdiag_token') : null
      const diagramLink = diagramPrefix && diagramGuid ? diagramPrefix + diagramGuid : null
      return { ...ld, name: linkName, wsmGuid, diagramGuid, wsmLink, diagramLink }
    })
    const pcInfo = pc.find(pc => pc.id === pcId)
    const linksImg = R.uniqBy(R.prop('image'), links.filter(l => !!l.image))
    const linksRef = links.filter(l => l.repairWsmId || l.otherLink || l.circuitDiagramId)

    const testsIds = _getDataByKey('map_pc_test').filter((map: any) => map.pcId == pcId).map((m: any) => m.testId)
    const test = _getDataByKey('test')
    const tests = test.filter(t => testsIds.includes(t.id))

    const referencesValuesDetailsIdsByPG = _getDataByKey('map_reference_value_detail_pg')
      .filter((m: any) => productGroupIds.includes(m.productGroupId))
      .map(m => m.referenceValueDetailId)

    const testReferences = tests.map((t: any) => {
      const referencesValuesIdsTestId = _getDataByKey('map_test_ref')
        .filter((m: any) => m.testId == t.id)
        .map((m: any) => m.smartdiagRefId)

      const references = _getDataByKey('reference_value_detail')
        .filter((r: any) => referencesValuesIdsTestId.includes(r.smartdiagRefId) && referencesValuesDetailsIdsByPG.includes(r.id))
        .map((r: any) => {
          const refName = _getDataByKey('reference_value').find(rv => rv.id === r.id)?.name
          const unit = _getDataByKey('unit').find(u => u.id === r.unitId)

          return ({
            refName,
            ...r,
            unit: unit?.code,
            ...R.mergeAll(
              getDefaultLocalisation(
                r,
                [
                  'valueResourceId',
                  'commentResourceId',
                  'additionalInfoResourceId',
                  'pinInfoResourceId',
                  'typeResourceId',
                  'concernedParts1ResourceId',
                  'memo1ResourceId',
                  'pN1ResourceId',
                  'concernedParts2ResourceId',
                  'memo2ResourceId',
                  'pN2ResourceId',
                  'concernedParts3ResourceId',
                  'memo3ResourceId',
                  'pN3ResourceId',
                  'concernedParts4ResourceId',
                  'memo4ResourceId',
                  'pN4ResourceId'
                ]
              )
            )
          })
        })

      return references
      })

    return {
      ...R.mergeAll((
        getDefaultLocalisation(
          pcInfo,
          [
            'repairInstructionResourceId'
          ]
        )
      )),
      references: testReferences && testReferences.length > 0 ? testReferences[0] : [],
      linksImg,
      linksRef
    }
  }

  const getSSDBInfoByTestId = (testId, config) => {
    const testSSDBlink = getDataByKey('_test_ssdb_link')
    const ssdb = getDataByKey('ssdb')
    const ssdbLinks = testSSDBlink.filter(testSSDBlink => testSSDBlink.testId === testId)
    console.log('ssdb ', testId, ssdbLinks)

    const ssdbInfo = ssdb.filter(ssdbCommon =>
      ssdbCommon.productGroups.some(pg => pg.id === selectedProduct.item.ssdbId)
      && R.pluck('ssdbNameId', ssdbLinks).includes(ssdbCommon.service_specification_name_id)
      && ssdbCommon.options.some(opt => R.pluck('ssdbCategoryId', ssdbLinks).includes(opt?.category?.id))
    )

    const configMap = {
      connectorPart: 'connectorParts',
      connectorBoth: 'connectorBothSides',
      connectorHarness: 'connectorHarness',
      arLink: '3d_ar_link'
    }

    return ssdbInfo.map(ssdbCommon => {
      Object.keys(config).forEach(key => {
        if (config[key] === false) {
          delete ssdbCommon[configMap[key]]
        }
      })
      return ({
        ...ssdbCommon,
        options: ssdbCommon.options.filter(opt => R.pluck('ssdbCategoryId', ssdbLinks).includes(opt?.category?.id))
      })
    })
  }

  const initializeProductData = async publications => {
    console.log('initializeProductData', publications)
    console.log('cache', cache)

    const loadDataAndCache = async () => {
      try {
        const loadedData = await getDataFromCache(publications)
        cache = loadedData
        setData(loadedData)
        console.log('Product data loaded')
        return 'Product data loaded'
      } catch (e) {
        console.error('Error getting data from cache:', e)
        throw e; // Rethrow the error to handle it further up the call stack
      }
    }

    if (Object.keys(cache).length === 0) {
      return loadDataAndCache(); // Load data from the cache if it's empty
    } else {
      console.log('Return data from cache');
      setData(cache);
      return 'Product data loaded from cache';
    }
  }

  const fetchAndCache = async (cache, request, target) => {
    console.log('fetchAndCache', cache, request, target)
    const response = await fetch(request, {
      credentials: 'include',
      referrerPolicy: 'unsafe-url'
    })

    return cache.put(target, response)
  }

  const cacheImages = async (cacheName: string, onTick: (processed: number, remains: number, total: number) => void): Promise<void> => {
    const queue = new PQueue({ concurrency: 10, autoStart: false })

    console.log('start caching images')

    const map_pc_detail_file_ids =  R.uniqBy(R.prop('fileId'), _getDataByKey('map_pc_detail_file').filter(map => map.type === 'image')).map(_ => _.fileId)
    const smartDiagCache = await caches.open(cacheName)

    console.log('linksWithImages', map_pc_detail_file_ids)


    await Promise.all(map_pc_detail_file_ids.map(async id => {
      const request = image(id)
      console.log('request', request)
      const target = imageWithoutToken(id)
      // console.log('target', target)
      const isCached = await smartDiagCache.match(target)
      // console.log('isCached', isCached)

      if (!isCached) {
        queue.add(() => fetchAndCache(smartDiagCache, request, target))
      }
    }))

    const ssdbFiles = R.uniqBy(R.prop('name'), _getDataByKey('file').filter(map => map.type === 'ssdb')).map(_ => _.name)

    await Promise.all(ssdbFiles.map(async name => {
      const request = ssdbImage(name)
      // console.log('request', request)
      const target = ssdbImage(name)
      // console.log('target', target)
      const isCached = await smartDiagCache.match(target)
      // console.log('isCached', isCached)

      if (!isCached) {
        queue.add(() => fetchAndCache(smartDiagCache, request, target))
      }
    }))

    const total = queue.size

    queue.start()
    queue.on('active', () => {
      onTick && onTick(total - queue.size, queue.size, total)
    })

    return queue.onEmpty()
  }

  return {
    initializeProductData,
    cacheImages,
    allData,
    getAllData,
    getDataByKey,
    diagnosticItemsBySelectedSeries,
    diagnosticItemsTreeWithPc,
    getDtcXCUs,
    getDtcDetails,
    getRelatedDTCs,
    getPcListByDiagnosticItems,
    getPCs,
    getQuestionsForPc,
    getTestForPc,
    getRepairInfoByPcId
  }
}

// const PUBLISH_PATH = `${process.env.REACT_APP_API_URI}/files/blobs/download/kid-publish`
const PUBLISH_PATH = `${process.env.REACT_APP_BLOB_URL}/kid-publish`

const getDataFromCache = async (publications) => {
  const result = {};

  for (const publication of publications) {
    const name = publication.model;
    const revision = publication.revision;

    const _file = `${name}::${revision}.json`;
    const file = _file.replace(/::/, '/'); // Replace '::' with '/'
    const request = new Request(`${PUBLISH_PATH}/${file}`);

    // const targetName = name.replace(/m7_/g, '').replace(/m6_/g, '');
    const targetName = name

    console.log('getDataFromCache', request, targetName);
    try {
      const match = await caches.match(request);
      console.log('match', match);
      if (match) {
        result[targetName] = await match.json();
      }
    } catch (error) {
      console.error(`Error parsing JSON for ${targetName}:`, error);
    }
  }

  return result;
};

export default useProductData


