import React from 'react'
import { FlexboxGrid } from 'rsuite'
import styled from 'styled-components'

const Card = styled.div`
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 4px 0;
  width: 300px;
  margin: -57px auto;
  @media (min-width: 768px) {
    margin-top: -40px!important;
  }
`
const CardBody = styled.div`
 padding: 5px;
`

const CouldntFind = ({ onClick, label }) =>
  <Card style={{ marginTop: -55, backgroundColor: '#F7F8FA' }}>
    <CardBody>
      <FlexboxGrid align='middle' onClick={onClick} style={{ cursor: 'pointer' }} justify='center'>
        <FlexboxGrid.Item  style={{ textAlign: 'center' }}>
          {label}
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </CardBody>
  </Card>

export default CouldntFind
