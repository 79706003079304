import React, {useEffect, useRef, useState} from 'react'
import { useTranslation } from 'react-i18next'
import {Grid, Header, Row, Col, Modal, FlexboxGrid, Checkbox, IconButton, Dropdown, Rate } from 'rsuite'
import { More, History, Reload, Copy, AddOutline, Exit, Location, FileUpload } from '@rsuite/icons'
import { push } from 'redux-first-history'
import OffRound from '@rsuite/icons/OffRound'
import Off from '@rsuite/icons/Off'
import Cookies from 'js-cookie'
import { useParams } from 'react-router-dom'

import { store } from '../store/store'
import useDiagnostic, { restartDiagnostic, initDiagnostic } from '../hooks/useDiagnostic'
import useHistoryData, { syncSessions } from '../hooks/useHistoryData'
import useNavigation from '../hooks/useNavigation'

import styles from '../styles/App.module.css'
import DtcDetailsModal from './dtcDetailsModal'
import SelectorDrawer from './selectordrawer'
import MenuItem from './menuitem'
import Button from './button'

import * as serviceWorkerRegistration from '../serviceWorkerRegistration'
import { version } from '../utils/constants'

import languages from '../config/languages'
import GlobalIcon from '@rsuite/icons/Global';

import i18n from 'i18next'
import { updateLanguage } from '../utils/api'
import RestartModal from "./restartModal";
import {RateInline} from "./rateAppModal";
import {useDispatch, useSelector} from "react-redux";
import {cleanListeners, setUpListeners} from "../utils/Kobd";
import {setKobdMessagesDebug} from "../store/application";

const renderIconButton = (props: any, ref: any) =>
  (
    <IconButton {...props} ref={ref} icon={<GlobalIcon/>} circle/>
  )

const saveAndCompareURLParams = (ignoreParams) => {
  const previousParamsJSON = localStorage.getItem('previousSearchParams');
  const previousParams = previousParamsJSON ? JSON.parse(previousParamsJSON) : {};

  const currentSearchParams = new URLSearchParams(window.location.search);
  const currentParams = Object.fromEntries(currentSearchParams);

  const paramsChanged = haveParamsChanged(previousParams, currentParams, ignoreParams);

  localStorage.setItem('previousSearchParams', JSON.stringify(currentParams));

  return paramsChanged;
}

const haveParamsChanged = (previousParams, currentParams, ignoreParams) => {
  const allKeys = new Set([...Object.keys(previousParams), ...Object.keys(currentParams)]);

  const keysToCheck = [...allKeys].filter(key => !ignoreParams.includes(key));

  for (const key of keysToCheck) {
    if (previousParams[key] !== currentParams[key]) {
      return true;
    }
  }

  return false;
}

const HeaderComponent = ({ online }: any) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [paramsHaveChanged, setParamsHaveChanged] = useState(false)
  const [restartModal, setRestartModal] = useState(false)
  const { navComponent, selectedItemsComponent, width, currentPage } = useNavigation()
  const { initialData, sessionInProgress }: any = useDiagnostic()
  const { loadDiagnostic } = useHistoryData()
  const [settingsModal, setSettingsModal] = useState(false)
  const [skipCacheImages, setSkipCacheImages] = useState(localStorage.getItem('skipCacheImages'))
  const points = useSelector(state => state.diagnostic.rate?.points)

  const params = window.location.search


 // const prevParams = localStorage.getItem('params') ? JSON.parse(localStorage.getItem('params')) : null;
  // console.log('prevParams', prevParams)
  console.log('params 333', JSON.stringify(params))

  const dispatch = useDispatch()

  const queryParams = new URLSearchParams(window.location.search)
  const isKobd = (queryParams.get('kobd') === '1') || (queryParams.get('kobd') === 'true') || (queryParams.get('restore') === 'true')  || (queryParams.get('restore') === '1')
  const fileInputRef = useRef(null)

  const handleFileSelect = event => {
    const fileReader = new FileReader()
    const selectedFile = event.target.files[0]
    fileReader.onload = event => {
      try {
        const state = JSON.parse(event.target.result)
        loadDiagnostic({ states: state })
        setRestartModal(false)
        setOpen(false)
      } catch (error) {
        console.error('Error parsing JSON:', error)
      }
    }

    fileReader.onerror = error => {
      console.error('Error reading file:', error)
    }

    fileReader.readAsText(selectedFile)
    event.target.value = ''
  }

  const openFile = () => {
    fileInputRef.current.click()
    setOpen(false)
  }

  const threeDotsMenu = [
    {
      label: t`Restart Diagnostic`,
      icon: <Reload width={25} height={25} fill='#1FA5A5' />,
      onClick: () => {
        setRestartModal(true)
        setOpen(false)
      }
    },
    {
      label: t`Diagnostic History`,
      icon: <History width={25} height={25} fill='#1FA5A5' />,
      onClick: () => {
        store.dispatch(push('/diagnosticHistory'));
        setOpen(false)
      }
    },
    {
      label: t`Open a project file`,
      icon: <FileUpload width={25} height={25} fill='#1FA5A5' />,
      onClick: openFile
    },
    /* {
      label: t`Request to add new DTC`,
      icon: <AddOutline width={25} height={25} fill='#1FA5A5' />,
      onClick: () => {
        store.dispatch(push('/registerDtc'))
        setOpen(false)
      }
    }, */
    {
      label: t`Log out`,
      icon: <Exit width={25} height={25} fill='#1FA5A5' />,
      onClick: () => {
        // const loginUrl = localStorage.getItem('loginUrl')
        const loginUrl = Cookies.get('loginUrl')
        localStorage.clear()
        console.log('logout', 'login URL', loginUrl)
        console.log('remove cookie')
        Cookies.remove('token', { domain: '.kbt-global.com' })
        if (loginUrl && loginUrl.length > 0) {
          window.location.replace(`${process.env.REACT_APP_API_URI.replace('/api/v1', '')}/dtcpluslogout?redirect=${loginUrl}`)
        } else {
          window.location.replace(`${process.env.REACT_APP_API_URI.replace('/api/v1', '')}/dtcpluslogout`)
        }
        setOpen(false)
      }
    },
    {
      label: t`Settings`,
      icon: <Location width={25} height={25} fill='#1FA5A5' />,
      onClick: () => {
        setSettingsModal(true)
        setOpen(false)
      }
    }
  ]


  useEffect(() => {
    setParamsHaveChanged(saveAndCompareURLParams(['lang', 'token']))
  }, [])

  useEffect(() => {
    console.log('paramsHaveChanged', paramsHaveChanged);

    if (!isKobd || paramsHaveChanged) {
      restartDiagnostic({ initialData });
    } else {
      initDiagnostic({ initialData })
    }
  }, [isKobd, paramsHaveChanged]);

  useEffect(() => {
    syncSessions()

    const debugFn = message => {
      if (message.data?.source === 'KID' || message.data?.source === 'KOBD') {
        dispatch(setKobdMessagesDebug(message.data))
      }
    }

    if (isKobd) setUpListeners(debugFn)
    return () => cleanListeners(debugFn)
  }, [])

  useEffect(() => {
    const handleBeforeUnload = e => {
      e.preventDefault()
      e.returnValue = ''
    }

    if (sessionInProgress && sessionInProgress._id && !points) {
      window.addEventListener('beforeunload', handleBeforeUnload)
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [sessionInProgress, points])

  const currentLanguage = localStorage.getItem('locale') || 'en-US'

  return (
    <Header>
      <RestartModal open={restartModal} onClose={() => setRestartModal(false)} />
      <DtcDetailsModal />
      <Modal backdrop='static' role='alertdialog' open={settingsModal} onClose={() => setSettingsModal(false)} size='md'>
        <Modal.Header>
          <b>{t`User settings`}</b>
        </Modal.Header>
        <Modal.Body>
          <Checkbox checked={!skipCacheImages } onChange={() => {
            if (!localStorage.getItem('skipCacheImages')) localStorage.setItem('skipCacheImages', 'yes')
            else localStorage.removeItem('skipCacheImages')
            setSkipCacheImages(!skipCacheImages)
          }}>{t`Pre-cache images for offline use`}</Checkbox>
        </Modal.Body>
        <Modal.Footer>
          <FlexboxGrid style={{ marginTop: '20px' }} justify='space-between'>
            <FlexboxGrid.Item colspan={10}>
              <Button width='100%' onClick={
                () => {
                  setSettingsModal(false)
                }}>
                {t`Close`}
              </Button>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </Modal.Footer>
      </Modal>
      <div className='header'>
        <Grid>
          <input
            style={{ display: 'none' }}
            type="file"
            ref={fileInputRef}
            onChange={handleFileSelect}
            accept="application/json"
          />
          <SelectorDrawer isOpen={open} isFull={false} onClose={() => setOpen(false)} body={
            threeDotsMenu.map(({ label, icon, onClick }, index) => <MenuItem key={index} label={label} icon={icon} onClick={onClick} />)
          }
          />
          <Row>
            <header className={styles.Header}>
              <FlexboxGrid justify='space-between'>
                <FlexboxGrid.Item>
                  <div className={styles.HeaderLogo}>
                    <p className={styles.HeaderLogo__img}>
                      <span className={styles.IconCorporationLogo} style={{
                        transform: width <= 600 ? 'scale(0.8)' : 'scale(1)'
                      }} onClick={() => {
                        if (sessionInProgress?._id) {
                          setRestartModal(true);
                        }
                      }}/>
                    </p>
                    <p className={styles.HeaderLogo__text} style={{ display: width <= 600 ? 'none' : 'block' }}>
                      <span>{t`Kubota intelligent Diagnostics`} <span style={{color: '#ddd' }}>v{version}</span></span>
                    </p>
                  </div>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item style={{ minWidth: 150 }}>
                  <FlexboxGrid justify='space-around' align='middle'>
                    <FlexboxGrid.Item>
                      <Dropdown renderToggle={renderIconButton}>
                        {languages.map(({ code, translation }, i) =>
                          <Dropdown.Item key={code} active={code === currentLanguage} eventKey={'6.' + i} onClick={() => {
                            console.log('language change', code);
                            localStorage.setItem('locale', code);
                            i18n.changeLanguage(code);
                            updateLanguage(code);
                          }}>{translation}</Dropdown.Item>
                        )}
                      </Dropdown>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                      <More rotate={90} width={30} height={30} style={{ cursor: 'pointer' }} onClick={() => setOpen(true)} />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                      <div>
                        {
                          online
                            ? <div><Off color='green' style={{ fontSize: '1.5em', marginRight: 0 }} /> {t`Online`}</div>
                            : <div><OffRound color='red' style={{ fontSize: '1.5em', marginRight: 0 }} /> {t`Offline`}</div>
                        }
                      </div>
                    </FlexboxGrid.Item>
                  </FlexboxGrid>
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </header>
          </Row>
          {false && <pre>{JSON.stringify(languages, null, 2)}</pre>}
          {currentPage !== '/' && currentPage !== '/selectModels' && (
            <FlexboxGrid style={{ marginTop: 10, marginBottom: 10, flexFlow: 'row' }} align='middle'>
              <FlexboxGrid.Item>
                {navComponent}
              </FlexboxGrid.Item>
              {selectedItemsComponent}
              {currentPage === '/causesTests' && (
                <FlexboxGrid.Item style={{ marginLeft: 'auto' }}>
                  <RateInline/>
                </FlexboxGrid.Item>
              )}
            </FlexboxGrid>
          )}
        </Grid>
      </div>
    </Header>
  )
}

export default HeaderComponent
