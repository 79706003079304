import {SecondaryButton} from "./button";
import {sendMessage} from "../utils/Kobd";
import {FlexboxGrid} from "rsuite";
import {camelCaseToWords, capitalizeFirstLetter} from "../utils";

const dataToACE = data => ({
  Function: data.map(d => ({
    Function: d.Function,
    XCU: d.xCU,
    Key: d.Item
  })),
  source: 'KID'
})

const LaunchKobd = ({ signals }) =>
  (
    <FlexboxGrid justify='space-around'>
      {signals.map((signal, index) => (
        <FlexboxGrid.Item key={index}>
          <SecondaryButton
            style={{ marginTop: 10 }}
            onClick={() => sendMessage(dataToACE([signal]))}>
            {`${signal.Function} (${signal.xCU} / ${capitalizeFirstLetter(camelCaseToWords(signal.Item))})`}
          </SecondaryButton>
        </FlexboxGrid.Item>
      ))}
    </FlexboxGrid>

  )

export default LaunchKobd
