import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'
import { Grid , FlexboxGrid, Panel, Row, Col, Divider, Loader } from 'rsuite'
import styled from 'styled-components'

import { openDTCModal } from '../store/application'
import Button  from './../components/button'
import arrowRight from '../images/arrow_right.png'
import SelectorList, { SelectData } from '../components/selectorlist'
import SelectorDrawer from '../components/selectordrawer'
import useProductData from '../hooks/useProductData'
import {filterChildrenAndRoot, parseDtcQuery, removeChildById, toOptions} from '../utils';
import useDiagnostic from '../hooks/useDiagnostic'
import ConfirmStartDiagnosticModal from '../components/confirmStartDiagnosticModal'
import { saveSessionStateToDb } from '../hooks/useHistoryData'

import { useTranslation } from 'react-i18next'
import { RootState } from '../store/store'
import DiagnosticItemList from "../components/diagnosticItemList";
import DTCRestrictModal from "../components/dtcRestrictModal";
import FeedBackButton from "../components/feedbackButton";

export const ActionDiv = styled.div`
  float: right;
  margin-right: 10px;
  margin-top: 8px;
  font-size: 15px;
  color: #009999;
  font-weight: bold;
  cursor: pointer;
    &:hover {
    text-shadow: 0px 1px 1px #444544
  }
`
const Title = styled.h6`
  padding-bottom: 5px;
  padding-top: 5px;
`

interface DrawerStateProperties {
  label: string
  value: number | string
}

const SelectDTC = () => {
  const { selectedDiagnosticItems, selectedDiagnosticItemsIds, setDtc, startDiagnostic, sessionInProgress } = useDiagnostic()

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [openConfirmStartDiagnosticModal, setOpenConfirmStartDiagnosticModal] = useState(false)
  const [DTCRestrictModalOpen, setDTCRestrictModalOpen] = useState(false)
  const [suggestions, setSuggestions] = useState <DrawerStateProperties[]> ([])
  const { diagnosticItemsBySelectedSeries, getRelatedDTCs, allData } = useProductData()
  const [drawerData, setDrawerData] = useState(diagnosticItemsBySelectedSeries)
  const fullState = useSelector((state: RootState) => state)

  const onOpenSelector = () => {
    console.log('diagnosticItemsBySelectedSeries', diagnosticItemsBySelectedSeries)
    // const options = toOptions(diagnosticItemsBySelectedSeries, 'name', 'id', t('Details'))
    const options = diagnosticItemsBySelectedSeries // MAIN-1091
    console.log('options', options, options.length)
    console.log('selectedDiagnosticItems', selectedDiagnosticItems)
    // for example TCU PST 5883/9
    // const relatedDtcs = getRelatedDTCs(selectedDiagnosticItemsIds) || []
    // const suggestionOptions = toOptions(relatedDtcs, 'name', 'originalDtcId', t('Details'))
    if (selectedDiagnosticItemsIds.length) {
      setDrawerData(diagnosticItemsBySelectedSeries.map(di => filterChildrenAndRoot(di, selectedDiagnosticItemsIds)).filter(s => !!s))
    }
    // setSuggestions(R.differenceWith((option1: SelectData, option2: SelectData) => option1.value === option2.value, suggestionOptions, selectedDiagnosticItems))
    setOpen(true)
  }
  const onRemoveFromList = (item: SelectData) => {
    console.log('onRemoveFromList', item, drawerData)
    setDrawerData(prevState => R.sortBy(R.prop('label'), [...prevState, item]))
    // const relatedDtcs = getRelatedDTCs(selectedDiagnosticItemsIds.filter(dtcId => item.value !== dtcId)) || []
    // const suggestionOptions = toOptions(relatedDtcs, 'name', 'originalDtcId', t('Details'))
    // setSuggestions(R.differenceWith((option1: SelectData, option2: SelectData) => option1.value === option2.value, suggestionOptions, selectedDiagnosticItems.filter(sDtc => sDtc.value !== item.value)))
    setDtc('remove', item)
  }
  const onAddToList = item => {
    const hasSymptom = selectedDiagnosticItems.some(sdi => !!sdi.symptom)

    if (hasSymptom && item.symptom) {
      setDTCRestrictModalOpen(true)
      return
    }
    setDrawerData(prevState =>
      !item.rootId
        ? prevState.filter(option => option.value !== item.value)
        : removeChildById(prevState, item.value, 'value')
    )
    // const relatedDtcs = getRelatedDTCs([...selectedDiagnosticItemsIds, item.value]) || []
    // const suggestionOptions = toOptions(relatedDtcs, 'name', 'originalDtcId', t('Details'))
    // setSuggestions(R.differenceWith((option1: SelectData, option2: SelectData) => option1.value === option2.value, suggestionOptions, [...selectedDiagnosticItems, item]))
    setDtc('add', item)
  }

  const startDiagnosticCheck = async () => {
    try {
      setLoading(true)
      await saveSessionStateToDb(fullState, 'inProgress')
      startDiagnostic('/causesTests')
    } finally {
      setLoading(true)
    }
  }

  const onStartDiagnostic = async () => {
    const hasDTCWithoutPC = selectedDiagnosticItems.filter(sldi => !!sldi.dtcId && !sldi.hasPc)

    if (hasDTCWithoutPC.length) {
      setOpenConfirmStartDiagnosticModal(true)
    } else {
      await startDiagnosticCheck()
    }
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const initialDtcs = parseDtcQuery(params.get('dtc'))
    console.log('initialDtcs', initialDtcs, sessionInProgress, diagnosticItemsBySelectedSeries)

    setTimeout(() => { // mod: need to add setTimeout because no re-render is done after adding dtc's
      if (!sessionInProgress?._id) {
        initialDtcs.forEach(({ xcu, spn, fmi, pcode }) => {
  
          for (const di of diagnosticItemsBySelectedSeries) {
            if (di.dtc) {
              const dtc = di.dtc
              if (dtc.xcu == xcu && dtc.spn == spn && dtc.fmi == fmi) {
                const alreadyInList = selectedDiagnosticItemsIds.includes(di.id)
                if (!alreadyInList) {
                  console.log('adding to list', di)
                  onAddToList(di)
                }
              }
            }
          }
        })
      }
    }, 0)
  }, [])

  return (
    <Grid>
      {
        false && (
          <>
          <pre>Drawer data: {drawerData && JSON.stringify(drawerData.length, null, 2)}</pre>
          <pre>Smart diag DTC: {allData && allData.smart_diag_dtc && allData.smart_diag_dtc.length}</pre>
          </>
        )
      }
      <DTCRestrictModal open={DTCRestrictModalOpen} onClose={() => setDTCRestrictModalOpen(false)} />
      <ConfirmStartDiagnosticModal
        isOpen={openConfirmStartDiagnosticModal}
        onClose={() => setOpenConfirmStartDiagnosticModal(false)}
        onProceed={startDiagnosticCheck}
        isContinue={!!sessionInProgress?._id}
        loading={loading}
        nonSupported={selectedDiagnosticItems.filter(sldi => !!sldi.dtcId && !sldi.hasPc)}
      />
      <SelectorDrawer
        isOpen={open}
        onClose={() => setOpen(false)}
        body={
        <>
          <FlexboxGrid style={{ height: '100%', flexDirection: 'column' }} align='middle' justify='start'>
            <FlexboxGrid.Item colspan={24} >
              <DiagnosticItemList
                data={drawerData}
                onLabelClick={({ value }) => dispatch(openDTCModal(value))}
                onActionBtnClick={onAddToList}
              />
            </FlexboxGrid.Item>
          </FlexboxGrid>
      </>
      }
      />
      <Panel header={t(sessionInProgress?._id ? 'Continue Diagnostic' : 'New Diagnostic')} shaded bordered>
        <Row>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Row>
              <Col xs={16}>
                <Title>{t('Current DTCs')}</Title>
              </Col>
              {!!selectedDiagnosticItems.length &&
              <Col xs={8}>
                <ActionDiv onClick={onOpenSelector}>{t('Add/Edit')} <img width={10} height={10} src={arrowRight} /></ActionDiv>
              </Col>}
            </Row>
            {!selectedDiagnosticItems.length
              ? <>
                  <p>{t('Nothing Selected')}</p>
                  <br />
                  <Button onClick={onOpenSelector}>{t('Select DTCs')}</Button>
                </>
              :
                <FlexboxGrid justify='space-between'>
                  <FlexboxGrid.Item colspan={24}>
                    <SelectorList
                      data={selectedDiagnosticItems}
                      withSearch={false}
                      actionText={t('Remove')}
                      onLabelClick={({ value }) => dispatch(openDTCModal(value))}
                      onActionBtnClick={onRemoveFromList}
                    />
                  </FlexboxGrid.Item>
                </FlexboxGrid>
            }
          </Col>
        </Row>
        {!!selectedDiagnosticItems.length &&
        <Row gutter={20}>
          <Divider/>
          <Col>
            <Button disabled={loading} onClick={onStartDiagnostic}>
              {loading
                ? <Loader />
                : t(sessionInProgress?._id ? 'Continue Diagnostic' : 'Start Diagnostic')
              }
            </Button>
          </Col>
          {/*<Col>*/}
          {/*  <SecondaryButton onClick={() => dispatch(push('/resolvedPC'))}>{t('Create a quick report')}</SecondaryButton>*/}
          {/*</Col>*/}
        </Row>
        }
      </Panel>
    </Grid>
  )
}

export default SelectDTC
