import React, { useState } from 'react'
import { clone, reverse } from 'ramda'
import { Modal, Button, Input, Panel, List } from 'rsuite'
import {sendMessage} from "../utils/Kobd"
import {useSelector} from "react-redux"
import { FaDownload, FaUpload } from 'react-icons/fa';

const addDummyProperties = message => {
  if (message.source === 'KOBD') return message

  if (message.Function?.[0]?.Function === 'AutoDiag')
    message.Function.forEach(element => {
      element.Factor = ''
    })

  if (message.Function?.[0]?.Function === 'datamonitor') {

    const units = [
      { name: 'Voltage', symbol: 'V' },
      { name: 'Power', symbol: 'W' },
      { name: 'Temperature', symbol: 'K' },
      { name: 'Current', symbol: 'A' },
      { name: 'Resistance', symbol: 'Ω' },
      { name: 'Energy', symbol: 'J' },
      { name: 'Force', symbol: 'N' },
      { name: 'Frequency', symbol: 'Hz' },
      { name: 'Pressure', symbol: 'Pa' },
      { name: 'Speed', symbol: 'm/s' }
    ]

    function getRandomNumber(min, max) {
      return Math.random() * (max - min) + min
    }

    function getRandomInteger(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min
    }

    function getRandomUnitSymbol(arr) {
      const randomUnit = arr[Math.floor(Math.random() * arr.length)]
      return randomUnit.symbol
    }

    if (message.hasOwnProperty('Function') && Array.isArray(message.Function)) {
      message.Function.forEach(element => {
        element.Value = getRandomNumber(0, 10).toFixed(2)
        element.Range = element.Range = getRandomInteger(0, 2)
        element.Unit = getRandomUnitSymbol(units)
      })
    }
  }

  return message
}


const KobdTestModal = () => {
  const [showModal, setShowModal] = useState(false)
  const [currentMessage, setCurrentMessage] = useState({})
  const [textAreaValue, setTextAreaValue] = useState('')
  const kobdMessagesDebug = useSelector(s => s.application.kobdMessagesDebug)

  const openModal = () => setShowModal(true)
  const closeModal = () => setShowModal(false)
  const handleChange = value => setTextAreaValue(value)
  const handleSend = () => {
    try {
      const json = JSON.parse(textAreaValue)
      sendMessage({ ...json, source: 'KOBD' })
    } catch (error) {

    }
  }

  const handleMessageClick = message => {
    setCurrentMessage(message)
    setTextAreaValue(JSON.stringify(addDummyProperties(clone(message)), null, 2))
  }

  const queryParams = new URLSearchParams(window.location.search)
  const isKobd = (queryParams.get('kobd') === '1') || (queryParams.get('kobd') === 'true') || (queryParams.get('restore') === 'true')  || (queryParams.get('restore') === '1')

  return (
    <>
      {isKobd && <button onClick={openModal}>KOBD Messages</button>}
      {showModal && <Modal open onHide={closeModal}>
        <Modal.Body>
          <div style={{ display: 'flex' }}>
            <Panel bordered style={{ width: '30%', marginRight: '10px', overflowY: 'auto' }}>
              <List>
                {reverse(kobdMessagesDebug).map((message, index) => (
                  <List.Item key={index} onClick={() => handleMessageClick(message)} style={{ cursor: 'pointer' }}>
                    {message.source === 'KID' ? <FaUpload color="red" /> : <FaDownload color="green" />}&nbsp;
                    {message.Function?.[0]?.Function}
                  </List.Item>
                ))}
              </List>
            </Panel>
            <Input
              as="textarea"
              rows={20}
              readOnly={currentMessage?.source === 'KOBD'}
              value={textAreaValue}
              onChange={handleChange}
              style={{ width: '70%' }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleSend} disabled={currentMessage?.source === 'KOBD'} appearance="primary">
            Response to KID
          </Button>
          <Button onClick={closeModal} appearance="subtle">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      }
    </>
  )
}

export default KobdTestModal
