import React from 'react'
import { FlexboxGrid, Modal, Loader } from 'rsuite'
import { useTranslation } from 'react-i18next'

import Button, {SecondaryButton} from './button'

const ConfirmStartDiagnosticModal = ({ isOpen, onProceed, onClose, loading, isContinue, nonSupported = [] }) => {
  const { t } = useTranslation()
  return (
    <Modal size='md' backdrop='static' keyboard={false} open={isOpen} onClose={onClose}>
      <Modal.Header>
        <Modal.Title>
          <h6>{t('Are You sure to proceed?')}</h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {t('Some of the DTCs are not supported yet')}
        </p>
        <p>
          {nonSupported.map((diagnosticItem, index) => (
            <span key={diagnosticItem.id}>
              <b>{diagnosticItem.label}</b>{index < nonSupported.length - 1 ? ', ' : ''}
            </span>
          ))}
        </p>
      </Modal.Body>
      <FlexboxGrid style={{ marginTop: '20px' }} justify='space-between'>
        <FlexboxGrid.Item colspan={10}>
          <Button width='100%' onClick={onProceed} disabled={loading}>
            {loading
              ? <Loader />
              : t(isContinue ? 'Continue Diagnostic' : 'Start Diagnostic')
            }
          </Button>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={10}>
          <SecondaryButton width='100%' onClick={onClose}>{t('Close')}</SecondaryButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </Modal>
  )
}

export default ConfirmStartDiagnosticModal
