import React, { useEffect } from 'react'
import { TypeAttributes } from 'rsuite/esm/@types/common'
import {Divider, Drawer, FlexboxGrid} from 'rsuite'

import { debounce } from '../utils'
import { SecondaryButton } from './button'
import { useTranslation } from 'react-i18next'

interface Props {
  isOpen: boolean
  onClose: Function
  body: React.ReactNode
  footer: React.ReactNode
  size?: TypeAttributes.Size
  isFull?: boolean,
  closeButton?: boolean
}

const SelectorDrawer = ({ isOpen, onClose, body, footer = null, size = 'sm', isFull, hasFooter }: Props) => {
  const [width, setWidth] = React.useState(window.innerWidth)

  const { t } = useTranslation()

  useEffect(() => {
    const debouncedHandleResize = debounce(() => {
      setWidth(window.innerWidth)
    }, 300)

    window.addEventListener('resize', debouncedHandleResize)

    return () => window.removeEventListener('resize', debouncedHandleResize)
  })

  const isBottom = width <= 992
  const full = isFull !== undefined ? isFull : isBottom
  return (
    <Drawer style={{ overflow: "auto" }} open={isOpen} onClose={onClose} size={size} full={full} placement={isBottom ? 'bottom' : 'right'}>
      {width <= 992 && full && size !=='xs' &&
        <Drawer.Header closeButton={false}>
          <SecondaryButton width='100%' onClick={onClose}>{t('Close')}</SecondaryButton>
        </Drawer.Header>
      }
      <Drawer.Body style={{
        padding: `20px ${(width <= 992 && full && size !=='xs') ? '20px' : '40px'}`,
        height: hasFooter ? 'calc(100% - 320px)' : 'calc(100% - 76px)'
      }}>
        {body}
      </Drawer.Body>
      {hasFooter &&
      <Drawer.Footer className='selector-drawer-footer'>
        {footer}
      </Drawer.Footer>
      }
    </Drawer>
  )
}

export default SelectorDrawer
