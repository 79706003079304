// import { getToken } from "./fetcher";

const API_URI = process.env.REACT_APP_API_URI

const exposeError = res => {
  console.log('res.status', res.status)
  if (res.status >= 200 && res.status < 300) {
    return res.json()
  } else {
    let error = new Error(res.statusText);
    error.response = res
    console.log('exposeError', error)
    throw error
  }
}

export const registerDTCRequest = (data: any) => fetch(`${API_URI}/diagnostic/reporteddtc`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(data)
  })
  .then(exposeError)

export const reportPCRequest = (data: any) => fetch(`${API_URI}/diagnostic/reportedcase`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(data)
  })
  .then(exposeError)

export const saveSessionRequest = (data: any) => fetch(`${API_URI}/diagnostic/session/user`, {
  method: 'POST',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
    },
  credentials: 'include',
  body: JSON.stringify(data)
  })
  .then(exposeError)
  .catch(error => {
    console.log('saveSessionRequest error')
    throw(error)
  })

export const getSessionsRequest = () => fetch(`${API_URI}/diagnostic/session/user`, {
  method: 'GET',
  headers: {
    'Accept': 'application/json',
    },
    credentials: 'include'
  })
  .then(exposeError)

export const getCurrentSessions= (withStates = true) => fetch(`${API_URI}/me/sessions?states=${withStates}`, {
  method: 'GET',
  headers: {
    'Accept': 'application/json',
  },
  credentials: 'include'
})
  .then(exposeError)

export const updateSessionRequest = (data: any, id: string) => fetch(`${API_URI}/diagnostic/session/${id}`, {
  method: 'PUT',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  },
  credentials: 'include',
  body: JSON.stringify(data)
})
  .then(exposeError)

export const deleteSessionRequest = (id: string) => fetch(`${API_URI}/diagnostic/session/${id}`, {
  method: 'DELETE',
  headers: {
    'Accept': 'application/json'
  },
  credentials: 'include'
})
  .then(exposeError)

export const deleteIntermediateSessionRequest = (id: string) => fetch(`${API_URI}/diagnostic/session/${id}/completely`, {
  method: 'DELETE',
  headers: {
    'Accept': 'application/json'
  },
  credentials: 'include'
})
  .then(exposeError)

export const cancelSessionRequest = (id: string) => fetch(`${API_URI}/diagnostic/session/cancel/${id}`, {
  method: 'PUT',
  headers: {
    'Accept': 'application/json'
  },
  credentials: 'include'
})
  .then(exposeError)

export const endSessionRequest = (id: string, saveToHistory = false) => fetch(`${API_URI}/diagnostic/session/end/${id}?save=${saveToHistory}`, {
  method: 'PUT',
  headers: {
    'Accept': 'application/json'
  },
  credentials: 'include'
})
  .then(exposeError)

export const endSessionAndResumeRequest = (idToCancel: string, idToResume: string, saveToHistory = false) => fetch(`${API_URI}/diagnostic/session/endandresume/${idToCancel}/${idToResume}?save=${saveToHistory}`, {
  method: 'PUT',
  headers: {
    'Accept': 'application/json'
  },
  credentials: 'include'
})
  .then(exposeError)

export const resumeRequest = (idToResume: string) => fetch(`${API_URI}/diagnostic/session/resume/${idToResume}`, {
  method: 'PUT',
  headers: {
    'Accept': 'application/json'
  },
  credentials: 'include'
})
  .then(exposeError)

export const finishSessionRequest = (id: string, hide = false) => fetch(`${API_URI}/diagnostic/session/finish/${id}`, {
  method: 'PUT',
  headers: {
    'Accept': 'application/json'
  },
  credentials: 'include'
})
  .then(exposeError)

export const updateLanguage = (applicationLanguage: string) => fetch(`${API_URI}/updatelanguages`, {
  method: 'POST',
  headers: {
    'Accept': 'application/json'
  },
  body: JSON.stringify({ applicationLanguage }),
  credentials: 'include',
  referrerPolicy: 'unsafe-url'
})
  .then(exposeError)

export const sendFeedback = data => fetch(`${API_URI}/diagnostic/feedback`, {
  method: 'POST',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(data),
  credentials: 'include',
  referrerPolicy: 'unsafe-url'
})
  .then(exposeError)


export const sendPhoto = (pcId, formData) =>
  fetch(`${API_URI}/diagnostic/uploadPhoto/${pcId}`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json'
    },
    body: formData,
    credentials: 'include',
    referrerPolicy: 'no-referrer'
  })
    .catch(error => console.error('Error:', error))

export const getPhotos = (pcId, photosName) => {
  const queryParams = photosName.map(name => `photosName[]=${encodeURIComponent(name)}`).join('&')
  const url = `${API_URI}/diagnostic/getPhotos/${pcId}?${queryParams}`

  return fetch(url, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
    },
    credentials: 'include'
  })
    .then(exposeError)
}
