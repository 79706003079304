import { useEffect, useState } from 'react'
import ReactJson from 'react-json-view'
import {Container, Content, Grid, Drawer, FlexboxGrid, Modal, Button, Input} from 'rsuite'
import { PersistGate } from 'redux-persist/integration/react'
import { connect, useSelector } from 'react-redux'
import { HistoryRouter as Router } from 'redux-first-history/rr6'
import { Routes, Route } from 'react-router-dom'
import Hotkeys from 'react-hot-keys'
import { Message, Progress, Loader } from 'rsuite'
import Cookies from 'js-cookie'

import { fetchCurrentUserAsync, fetchToken, fetchCurrentSessionsAsync } from './store/user'
import { selectConnectionStatus, setConnectionStatus, setQueryParams,  setConfigs, selectConfigs, setPublications, selectPublications, setSortWeights, setTranslations, setPins, setSignalMapping } from './store/application'
import { history, persistor, RootState } from './store/store'
import { setDiagnosticState, setInitialData, setSeries, setSSDBSeries, setState } from './store/diagnostic'
import { fetchWithCredentials, setToken } from './utils/fetcher'

import { useAppDispatch } from './hooks'

import 'rsuite/dist/rsuite.min.css'
import './App.css'
import './styles/custom.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import HeaderComponent from './components/header'
import SelectDTC from './containers/selectDTC'
import DiagnosticHistory from './containers/diagnosticHistory'
import CausesTests from './containers/causesTests'
import RegisterDTC from './containers/registerDtc'
import ResolvedPC from './containers/resolvedPC'
import Splash from './containers/splash'
import useProductData from './hooks/useProductData'
import { cacheName } from './utils/constants'
import { useServiceWorker } from './hooks/useServiceWorker'

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import {syncLocalImages} from "./utils/cache";
import KobdTestModal from "./components/KobdTestModal";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {},
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    lng: 'en-US'
  })

function getToken() {
  const tokenString = localStorage.getItem('smartdiag_token')
  if (tokenString) return tokenString
}

const redirectToSignin = () => {
  if (window.navigator.onLine) {
    console.log('redirectToSignin')

    // const loginUrl = localStorage.getItem('loginUrl')
    const loginUrl = Cookies.get('loginUrl')

    if (loginUrl && loginUrl.length > 0) {
      return window.location.replace(loginUrl)
    }

    /* const params = new URLSearchParams(window.location.search)
    const queryParams = Object.fromEntries(params)
    let redirectUrl = process.env.REACT_APP_APP_URL

    const { region, model, pCode } = queryParams
    if (region && model) redirectUrl += `?region=${region}&model=${model}`
    else if (region) redirectUrl += `?region=${region}`
    else if (model) redirectUrl += `?model=${model}`
    if (pCode) redirectUrl += `&pCode=${pCode}` */

    // const redirectUrl = process.env.REACT_APP_APP_URL
    // const redirectUrl = decodeURIComponent(`${process.env.REACT_APP_API_URI}/dtcpluslogin`)

    // const url = `${process.env.REACT_APP_AUTH_URL}/signin?dtcPlus=true&redirect=${redirectUrl}`
    //const url = `${process.env.REACT_APP_AUTH_URL}/signin?dtcplus=true`
    const currentUrl = window.location.href
    const encodedUrl = encodeURIComponent(currentUrl)
    const url = process.env.REACT_APP_SIGNIN_URL || `${process.env.REACT_APP_AUTH_URL}/signin?redirect=${encodedUrl}`
    window.location.replace(url)
  }
}

function App(props: any) {
  const dispatch = useAppDispatch()
  const [userLoading, setUserLoading] = useState(true)
  const [debug, setDebug] = useState(false)
  const [configsInitialized, setConfigsInitialized] = useState<any>(false)
  const [initialized, setInitialized] = useState<any>(false)
  const [imagesInitializedPercent, setImagesInitializedPercent] = useState<any>(false)
  const { initializeProductData, cacheImages } = useProductData()
  const [replications, setReplications] = useState([])
  const [replicationCount, setReplicationCount] = useState(0)
  const [updatesAvailable, setUpdatesAvailable] = useState(false)
  const [appUpdateAvailable, setAppUpdateAvailable] = useState(false)
  const { waitingWorker, showReload, reloadPage, skipReload } = useServiceWorker()
  const [publicationsToLoad, setPublicationsToLoad] = useState(1000)
  const prevQueryParams = useSelector(s => s.application.queryParams)

  const sessions = useSelector((state: RootState) => state.user.sessions)

  // Function to send a message to the WinForms application
  const sendMessageToKobd = () => {
    const message = 'Message from KID'
    window.chrome.webview.postMessage(message)
  };

  // internal variable for PIN lookup - only used once
  let modelCodeProductGroupMapping = []


  useEffect(() => {
    if (showReload && waitingWorker) {
      setAppUpdateAvailable(true)
    } else {
      setAppUpdateAvailable(false)
    }
  }, [waitingWorker, showReload, reloadPage, skipReload]);

  useEffect(() => {
    const init = async () => {
      let configs = {}
      console.log('init', process.env)
      if (!initialized && !configsInitialized) {
        const params = new URLSearchParams(window.location.search)
        console.log('params', params)
        const token = params.get('token') || getToken()
        if (token) localStorage.setItem('smartdiag_token', token)

        let lang = params.get('lang') || navigator.language || 'en-US'

        setToken(token)

        if (params.get('onetimetoken')) {
          const token = await dispatch(fetchToken(params.get('onetimetoken')))
          setToken(token.payload)
        }


        const loadConfigsAndPublications = async () => {
          const info = await fetchWithCredentials(`${process.env.REACT_APP_API_URI}/diagnostic/info`)
          // const { configs, publications } = await info.json()

          const infoResult = await info?.json()
          configs = infoResult.configs
          const publications = infoResult.publications

          const sortWeights = configs && configs.find(_ => _.key === 'sortWeights')
          if (sortWeights && sortWeights.value) dispatch(setSortWeights(JSON.parse(sortWeights.value)))

          const translations = configs && configs.find(_ => _.key === 'translations_en-US')
          if (translations && translations.value) dispatch(setTranslations(JSON.parse(translations.value)))

          const modelCodeProductGroupMappingData = configs && configs.find(_ => _.key === 'modelCodeProductGroupMapping')
          if (modelCodeProductGroupMappingData && modelCodeProductGroupMappingData.value) {
            modelCodeProductGroupMapping = JSON.parse(modelCodeProductGroupMappingData.value)
          }


          const resources = {
            /* en: {
              translation: JSON.parse(translations.value)
            },
            jp: {
              translation: {}
            } */
          }

          console.log('configs', configs)


          for (const config of configs) {
            if (config.key && config.key.startsWith('translations_')) {
              const langKey = config.key.replace('translations_', '')
              // resources[langKey] = { translation: JSON.parse(config.value) }
              i18n.addResourceBundle(langKey, 'translation', JSON.parse(config.value))
              console.log('addResourceBundle', 'langKey', langKey, JSON.parse(config.value))
            }
          }

          console.log('resources', resources)

          dispatch(setPublications(publications))
          setInitialized(true)
          setConfigsInitialized(true)
          dispatch(setConfigs(configs))
          setPublicationsToLoad(publications.length)
          loadPublications(publications)
        }

        try {
          await loadConfigsAndPublications()
        } catch (e) {
          // MAIN-1116
          redirectToSignin()
        }

        const getUser = async () => {
          const params = new URLSearchParams(window.location.search)


          const redirect = () => {
            let loginUrl = null
            if (localStorage.getItem('smartdiag_external_data') !== '') {
              try {
                const externalData = localStorage.getItem('smartdiag_external_data')
                const parsed = JSON.parse(externalData || '')
                if (parsed && parsed.loginUrl) {
                  loginUrl = parsed.loginUrl
                }
              } catch (e) {

              }
            }
            if (loginUrl) {
              let redirect = loginUrl
              if (!redirect.includes('http')) redirect = 'http://' + redirect
              window.location.replace(redirect)
            } else {
              if (!window.location.pathname.includes('/landing')) {
                localStorage.removeItem('smartdiag_token')
                console.log('redirect to signin')
                redirectToSignin()
              }
            }
          }
          if (true) {
            const currentUser = await dispatch(fetchCurrentUserAsync())
            console.log('currentuser', currentUser)
            await dispatch(fetchCurrentSessionsAsync())
            if (currentUser && currentUser.payload) {
              if (!currentUser.payload._id) redirect()
            } else {
              redirect()
            }
            if (params.get('lang') && params.get('lang').length > 0) {
              setTimeout(() => {
                localStorage.setItem('locale', lang)
                i18n.changeLanguage(lang)
              }, 0)
            } else if (currentUser.payload && !lang && currentUser.payload.applicationLanguage) {
              console.log('change language from user\'s application language', currentUser.payload.applicationLanguage)
              // applicationLanguage = currentUser.payload.applicationLanguage
              setTimeout(() => {
                localStorage.setItem('locale', currentUser.payload.applicationLanguage)
                i18n.changeLanguage(currentUser.payload.applicationLanguage)
              }, 0)
            }
            else if (localStorage.getItem('locale')) {
              console.log('set language from locale')
              setTimeout(() => {
                i18n.changeLanguage(localStorage.getItem('locale'))
              }, 0)
            }
            else {
              setTimeout(() => {
                localStorage.setItem('locale', lang)
                i18n.changeLanguage(lang)
              }, 0)
            }
            setUserLoading(false)

          } else {
            redirect()
          }
        }

        await getUser()
      }

      dispatch(setConnectionStatus(window.navigator.onLine ? 'online' : 'offline'))
      window.addEventListener('offline', () => {
        dispatch(setConnectionStatus('offline'))
      })

      window.addEventListener('online', () => {
        dispatch(setConnectionStatus('online'))
        syncLocalImages()
      })


    }
    init()
    return () => {}

  }, [])

  const online = useSelector(selectConnectionStatus) === 'online'
  const translations = useSelector((state: RootState) => state.application.translations)

  // const PUBLISH_PATH = `${process.env.REACT_APP_API_URI}/files/blobs/download/kid-publish`
  const PUBLISH_PATH = `${process.env.REACT_APP_BLOB_URL}/kid-publish`

  // const tmp = 'https://smartdiagdev.blob.core.windows.net/kid-publish/original_dtc/13.json?sv=2024-05-04&ss=btqf&srt=sco&spr=https&st=2024-09-23T20%3A08%3A32Z&se=2027-01-05T04%3A08%3A32Z&sp=rwdlacupi&sig=tiNG8HnmfH1uyRrpBEyAmsg2O7AJAEuAPYTj4M9a374%3D'

  //

  const loadPublications = (publications: []) => {
    console.log('publications', publications)

    setUpdatesAvailable(false)
    setInitialized(false)

    let currentReplications = []
    let cnt = 0

    const loadAll = async () => {

      const SAS = await (await fetchWithCredentials(`${process.env.REACT_APP_API_URI}/files/blobs/sas`))?.text() //  `?sv=2024-05-04&ss=btqf&srt=sco&spr=https&st=2024-09-23T20%3A06%3A09Z&se=2027-01-05T04%3A06%3A09Z&sp=rwdlacupi&sig=5%2FaLC1shbqUW7k0YPUGEW%2BhdO59lvrgONGt%2BHZj%2Fw3g%3D`

      console.log('SAS', SAS)

      console.log(process.env)

      const loadData = async (publication: any) => new Promise((resolve) => {
        const name: string = publication.model
        const revision: string = publication.revision
        const loaded = () => {
          cnt ++
          currentReplications.push(name)
          setReplicationCount(cnt)
          setReplications(currentReplications)
          resolve(true)
        }
        const _file = `${name}::${revision}.json`
        const file = _file.replace(/::/, '/')
        // const request = new Request(`${PUBLISH_PATH}/${file}?token=${getToken()}`)
        const request = new Request(`${PUBLISH_PATH}/${file}${SAS}`)
        // console.log('request', request)

        // fetch(`${PUBLISH_PATH}/${file}`, { credentials: 'include' })

        caches.open(cacheName).then(async cache => {
          /* cache.add(request).then(() => {
            loaded()
          }) */
          const isCached = await cache.match(`${PUBLISH_PATH}/${file}`)
          if (isCached) loaded()
          else {
            fetch(request, {
              signal: AbortSignal.timeout(60000),
              // credentials: 'include',
              referrerPolicy: 'unsafe-url'
            }).then(async response => {
              console.log('response', file, response)
              try {
                await cache.put(`${PUBLISH_PATH}/${file}`, response)
              } catch (e) {
                console.error('Error in cache.put')
                console.error(response)
                console.error(e)
              }
              loaded()
            }).catch(e => {
              console.error(e)
              loaded()
            })
          }
        })
      })

      await Promise.all(publications.map((publication) => loadData(publication)))
      await initializeProductData(publications)

      console.log('initializeProductData complete')

      const params = new URLSearchParams(window.location.search)
      const queryParams = Object.fromEntries(params)

      console.log('Query params', params, queryParams)

      /* if (queryParams.skipCacheImages !== 'true' && (!localStorage.getItem('skipCacheImages') || localStorage.getItem('skipCacheImages') === 'yes')) {
        await cacheImages(
          cacheName,
          (processed, remains, total) => setImagesInitializedPercent(Math.floor((processed / total) * 100)))
      } */

      cacheImages(
        cacheName,
        (processed, remains, total) => setImagesInitializedPercent(Math.floor((processed / total) * 100)))
      // localStorage.setItem('lastSync', new Date().getTime().toString())

      const initialData: any = {}

      let models = []

      for (const publication of publications) {
        const name: string = publication.model
        const revision: string = publication.revision

        const request = new Request(`${PUBLISH_PATH}/${name}/${revision}.json`) // ?token=${getToken()}`

        const targetName = name
          .replace(/m7_/g, '')
          .replace(/m6_/g, '')
          .replace(/m5_/g, '')

        if (queryParams.model && targetName === 'product_group') {
          const match = await caches.match(request)
          if (match) {
            models = await match.json()
            for (const _ of models) {
              if (_.name === queryParams.model) {
                initialData.model = { label: _.name, value: _.id}
              }
            }
          }
        }
      }

      let pins: any = []
      const loadPins = async () => new Promise((resolve) => {

        const loaded = () => resolve(true)

        const file = 'pins/pins.json'
        const request = new Request(`${PUBLISH_PATH}/${file}${SAS}`)

        caches.open(cacheName).then(async cache => {
          const isCached = await cache.match(`${PUBLISH_PATH}/${file}`)
          if (isCached) {
            console.log('loaded pins from cache')
            pins = await isCached.json()
            loaded()
          }
          else {
            fetch(request, {
              signal: AbortSignal.timeout(60000),
              referrerPolicy: 'unsafe-url'
            }).then(async response => {
              console.log('pins response', file, response)
              try {
                await cache.put(`${PUBLISH_PATH}/${file}`, response)
              } catch (e) {
                console.error('Error in cache.put')
                console.error(response)
                console.error(e)
              }
              pins = await (await cache.match(`${PUBLISH_PATH}/${file}`))?.json()
              loaded()
            }).catch(e => {
              console.error(e)
              loaded()
            })
          }
        })
      })

      await loadPins()

      console.log('pins loaded', pins, modelCodeProductGroupMapping)
      

      if (queryParams && queryParams.pin) {
        console.log('pin', queryParams.pin)
        initialData.pin = queryParams.pin
        const matchingPin = pins.find((_: any) => _.BIDN === queryParams.pin)
        console.log('matchingPin', matchingPin)
        if (matchingPin) {
          const matchingProductGroup = modelCodeProductGroupMapping.find((_: any) => _.model_code === matchingPin.BMCMDLCDF)
          console.log('pin mapping - matchingProductGroup', matchingProductGroup)
          if (matchingProductGroup) {
            initialData.initialProductGroup = matchingProductGroup
          }
        }
      }

      if (queryParams && queryParams.model && queryParams.sn) {
        console.log('model and sn', queryParams.model, queryParams.sn)
        const matchingPin = pins.find((_: any) => _.BMCSRLNO === queryParams.sn && _.BASIC_MODEL_NAME === queryParams.model)
        if (matchingPin) {
          const matchingProductGroup = modelCodeProductGroupMapping.find((_: any) => _.model_code === matchingPin.BMCMDLCDF)
          console.log('model & sn mapping - matchingProductGroup', matchingProductGroup)
          if (matchingProductGroup) {
            initialData.initialProductGroup = matchingProductGroup
          }
        }
      }

      if (queryParams && queryParams.sn) {
        initialData.sn = queryParams.sn
      }

      if (queryParams && queryParams.series) {
        initialData.series = queryParams.series
      }


      if (Object.keys(initialData).length > 0) {
        dispatch(setInitialData(initialData))
      }

      let signalMapping: any = []
      const loadSignalMapping = async () => new Promise((resolve) => {

        const loaded = () => resolve(true)

        const file = 'signals/signalMapping.json'
        const request = new Request(`${PUBLISH_PATH}/${file}${SAS}`)

        caches.open(cacheName).then(async cache => {
          const isCached = await cache.match(`${PUBLISH_PATH}/${file}`)
          if (isCached) {
            console.log('loaded loadSignalMapping from cache')
            signalMapping = await isCached.json()
            loaded()
          }
          else {
            fetch(request, {
              signal: AbortSignal.timeout(60000),
              referrerPolicy: 'unsafe-url'
            }).then(async response => {
              console.log('loadSignalMapping response', file, response)
              try {
                await cache.put(`${PUBLISH_PATH}/${file}`, response)
              } catch (e) {
                console.error('Error in cache.put')
                console.error(response)
                console.error(e)
              }
              signalMapping = await (await cache.match(`${PUBLISH_PATH}/${file}`))?.json()
              loaded()
            }).catch(e => {
              console.error(e)
              loaded()
            })
          }
        })
      })

      await loadSignalMapping()

      console.log('signalMapping loaded', signalMapping)
      
      dispatch(setQueryParams(queryParams))
      dispatch(setPins(pins))
      dispatch(setSignalMapping(signalMapping))

      // TO DO: we remove query params from url to remove token but we need to keep kobdModel query param
      // window.history.pushState({}, null, '/')

      setInitialized(true)
    }

    loadAll()
  }

  let percent = Math.floor((replicationCount / publicationsToLoad) * 100)
  if (percent > 100) percent = 100

  if (userLoading || !initialized) return (
    <FlexboxGrid justify='center'>
      <FlexboxGrid.Item colspan={10} style={{ paddingTop: 300, textAlign: 'center' }}>
        <div style={{ paddingBottom: 30 }}>{imagesInitializedPercent ? 'Images' : 'Data' } initializing may take 2-3 minutes depending on your internet connection...</div>
        <Loader size='md' />
        {
          false && replicationCount > 0 && !imagesInitializedPercent && percent < 100 && (
            <Progress.Line percent={percent} status='active' />
          )
        }
        {
          imagesInitializedPercent > 0 && (
            <Progress.Line percent={imagesInitializedPercent} status='active' />
          )
        }
      </FlexboxGrid.Item>
    </FlexboxGrid>
  )

  const access = true // props.state && props.state.user && props.state.user.currentUser && props.state.user.currentUser.kitsRoles && !props.state.user.currentUser.kitsRoles.find(_ => _.includes('_Dealer'))

  return (
    <Hotkeys
      keyName='alt+s'
      onKeyUp={() => setDebug(!debug)}
  >
    <PersistGate persistor={persistor}>
      <Router history={history}>
        <Container style={{ height: '100%' }}>
         <KobdTestModal />
        { false && <pre>Translations {JSON.stringify(translations, null, 2)}</pre> }
        <Drawer open={debug} placement='right' onClose={() => setDebug(false)} >
          <div style={{ height: '100%', overflowY: 'scroll', padding: 20}}>
            <h6 style={{ lineHeight: '40px' }}>Redux store</h6>
            <ReactJson src={props.state} collapsed={2} />
          </div>
        </Drawer>
        <HeaderComponent online={online} />
        {
          !access ? (
            <Content>
              <Grid>
                <Message showIcon type="info" header="Informational">
                This application is currently in development
                </Message>
              </Grid>
            </Content>
          ) : (
            <>
              <Content>
                <Grid style={{ height: '100%' }}>
                  <Routes>
                    <Route path="/" element={<Splash />} />
                    <Route path="/selectModels" element={<Splash />} />
                    <Route path="/selectDtc" element={<SelectDTC />} />
                    <Route path="/registerDtc" element={<RegisterDTC />} />
                    <Route path="/resolvedPC" element={<ResolvedPC />} />
                    <Route path="/diagnosticHistory" element={<DiagnosticHistory />} />
                    <Route path="/causesTests" element={<CausesTests />} />
                  </Routes>
                </Grid>
              </Content>
            </>
          )
        }
      </Container>

      <Modal backdrop='static' keyboard open={appUpdateAvailable} onClose={() => setAppUpdateAvailable(false)}>
        <Modal.Header>
          <Modal.Title>Updates available</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div>
            A new version of this page is available!
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => reloadPage()} appearance="primary">
            Apply now (reload)
          </Button>
          <Button onClick={() => skipReload()} appearance="subtle">
            Reload on next visit
          </Button>
        </Modal.Footer>
      </Modal>
      </Router>
    </PersistGate>
    </Hotkeys>
  )
}

const mapState = (state: any) => ({
  state
})

const connector = connect(mapState)

export default connector(App)

// https://kits2.intra.kbt-global.com/?from=DTC&region=NA/AU/JP&model=M7002&dtc=NAC:520192:2:
