export const sendMessage = data => {
  if (window.chrome && window.chrome.webview) {
    window.chrome.webview.postMessage(data)
    console.log('Sent message to WebView:', data)
  } else {
    window.postMessage(data, '*')
    console.log('Sent message to window:', data)
  }
}
export const messageHandlers = []

const callAllHandlers = event => {
  messageHandlers.forEach(handler => {
    try {
      handler(event)
    } catch (e) {
      console.error('Error in message handler:', e)
    }
  })
}

export const setUpListeners = handleIncomingMessage => {
  messageHandlers.push(handleIncomingMessage)

  console.log('messageHandlers', messageHandlers)
  try {
    if (window.chrome && window.chrome.webview) {
      window.chrome.webview.onmessage = callAllHandlers
      console.log('Listening for messages from WebView.')
    } else {
      // Ensure we don't add the same event listener multiple times
      window.removeEventListener('message', callAllHandlers)
      window.addEventListener('message', callAllHandlers)
      console.log('WebView not detected. Listening for messages from other sources.')
    }
  } catch (e) {
    console.error('Error setting up message listeners:', e)
  }
}

export const cleanListeners = fn => {
  const index = messageHandlers.indexOf(fn)
  if (index > -1) {
    messageHandlers.splice(index, 1)
    console.log('Removed specific listener for messages.')
  }

  if (messageHandlers.length === 0) {
    if (window.chrome && window.chrome.webview) {
      window.chrome.webview.onmessage = null
      console.log('Removed all listeners for messages from WebView.')
    } else {
      window.removeEventListener('message', callAllHandlers)
      console.log('Removed all listeners for messages from other sources.')
    }
  }
}
