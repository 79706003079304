import {FlexboxGrid, Table} from 'rsuite'
import {useEffect, useState} from "react"
import {setUpListeners, sendMessage, cleanListeners} from "../utils/Kobd"
import {SecondaryButton} from "./button";

const { Column, HeaderCell, Cell } = Table

const dataToACE = (data, Function) => ({
  Function: data.map(d => ({
    Function,
    XCU: d.xCU,
    Key: d.Item
  })),
  source: 'KID'
})

const ColorfulCell = ({ rowData, dataKey }) => {
  let color
  switch (rowData.Range) {
    case 0:
      color = 'black'
      break
    case 1:
      color = 'green'
      break
    case 2:
      color = 'red'
      break
    default:
      color = 'inherit'
  }

  const value = rowData[dataKey]

  return (
    <div style={{ color }}>
      <span>{value}</span>
    </div>
  )
}

const DataMonitor = ({ signals }) => {
  const [data, setData] = useState(signals)

  const handleIncomingDataMonitorMessage = incomingData => {
    if (incomingData.data?.source === 'KOBD' && incomingData.data?.Function?.[0].Function === 'datamonitor') {
      console.log('DataMonitor get data', incomingData)
      const updatedData = data.map(d => {
        const found = incomingData.data?.Function.find(incoming => incoming.XCU === d.xCU && incoming.Key === d.Item) || {}
        return {
          ...d,
          ...found
        }
      })

      setData(updatedData)
    }
  }

  const showGraph = () => sendMessage(dataToACE(signals, 'datamonitor_graph'))

  useEffect(() => {
    setUpListeners(handleIncomingDataMonitorMessage)
    return () => cleanListeners(handleIncomingDataMonitorMessage)
  }, [])

  useEffect(() => {
    const intervalId = setInterval(() => {
      sendMessage(dataToACE(signals, 'datamonitor'))
    }, 3000)

    return () => clearInterval(intervalId)
  },[])

  return (
    <div style={{ marginTop: 10 }}>
      <h5>Data Monitor</h5>
      <Table
        height={signals.length * 60}
        data={data}
      >
        <Column flexGrow={1}>
          <HeaderCell>Signal</HeaderCell>
          <Cell dataKey='KOBD_Signal_to_be_displayed_in_UI' />
        </Column>
        <Column width={80}>
          <HeaderCell>Value</HeaderCell>
          <Cell>
            {rowData => <ColorfulCell rowData={rowData} dataKey="Value" />}
          </Cell>
        </Column>
        <Column  width={60}>
          <HeaderCell>Unit</HeaderCell>
          <Cell dataKey="Unit" />
        </Column>
      </Table>
      <FlexboxGrid justify='space-around'>
        <FlexboxGrid.Item>
          <SecondaryButton onClick={showGraph}>Show Graph</SecondaryButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </div>
  )
}

export default DataMonitor
