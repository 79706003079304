import * as R from 'ramda'

export const formatDateLong = date => {
  const day = date.getDate().toString().padStart(2, '0')

  const monthNames = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ]
  const month = monthNames[date.getMonth()]

  const year = date.getFullYear()

  return `${day} ${month} ${year}`
}


export const toOptions = (array: any[], labelKey = 'name', valueKey = 'id', subLabel = 'Details') =>
  array.map(item => ({ value: item[valueKey], label: item[labelKey], subLabel: item[subLabel] || 'Details', item }))

export const debounce = (fn: Function, ms: number) => {
  let timer: NodeJS.Timeout | undefined
  return () => {
    // @ts-ignore
    clearTimeout(timer)
    timer = setTimeout(function () {
      timer = undefined
      // @ts-ignore
      fn.apply(this, arguments)
    }, ms)
  }
}

const value = (obj: any, key: string) => R.path(key.split('.'), obj)

export const toDictionary = (objects: any, key: string) => objects.reduce((dictionary: any, obj: any) => {
  //@ts-ignore
  dictionary[value(obj, key)] = obj
  return dictionary
}, {})

export const camelCaseToWords = (string) => string.replace('diagnostic/', '').replace(/([A-Z]+)*([A-Z][a-z])/g, "$1 $2")
export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1)
export const splitWordByCapitalLetter = (string) => string.split(/(?=[A-Z])/).join(' ')

/**
 * // Example usage:
 * const items = [
 *   { id: 1, parent: null },
 *   { id: 2, parent: 1 },
 *   { id: 3, parent: 1 },
 *   { id: 4, parent: 2 },
 *   { id: 5, parent: 2 },
 * ]
 *
 * [
 *   {
 *     "id": 1,
 *     "parent": null,
 *     "children": [
 *       {
 *         "id": 2,
 *         "parent": 1,
 *         "children": [
 *           {
 *             "id": 4,
 *             "parent": 2,
 *             "children": []
 *           },
 *           {
 *             "id": 5,
 *             "parent": 2,
 *             "children": []
 *           }
 *         ]
 *       },
 *       {
 *         "id": 3,
 *         "parent": 1,
 *         "children": []
 *       }
 *     ]
 *   }
 * ]
 */
export const buildTree = (items, idField = 'id', parentField = 'parent') => {
  const itemMap = new Map()
  const treeRoots = []

  for (const item of items) {
    itemMap.set(item[idField], { ...item, children: [] })
  }

  for (const item of items) {
    const current = itemMap.get(item[idField])
    const parentItem = itemMap.get(item[parentField])

    if (parentItem) {
      parentItem.children.push(current)
    } else {
      treeRoots.push(current)
    }
  }

  return treeRoots
}

export const flatten = nodeList => {
  let result = []
  nodeList.forEach((node) => {
    result.push(node)
    result = result.concat(flatten(node.children || []))
  })
  return result
}

const _flattenChildren = (item, result = []) => {
  const { children, ...currentItem } = item
  result.push(currentItem)
  if (children) {
    children.forEach(child => _flattenChildren(child, result))
  }

  return result
}

export const makeTreeWithFlattenChildren = R.map(item => {
  const flattened = _flattenChildren(item)
  const children = flattened.slice(1)

  return {
    ...R.omit(['children'], item),
    hasChildren: !!children.length,
    children: children
  }
})

export const removeChildById = (array, childId, prop = 'id') =>
  array.reduce((acc, item) => {
    const hasChildToRemove = item?.children?.some(child => child[prop] === childId)
    const filteredChildren = item?.children?.filter(child => child[prop] !== childId)
    const hadChildrenInitially = item?.children?.length > 0
    const shouldRemoveParent = hadChildrenInitially && hasChildToRemove && filteredChildren.length === 0

    if (!shouldRemoveParent) {
      acc.push({ ...item, children: filteredChildren })
    }

    return acc
  }, [])


/**
 const treeData = {
  id: 'root',
  children: [
    {
      id: '1',
      children: [
        { id: '1-1', children: [] },
        { id: '1-2', children: [] }
      ]
    },
    {
      id: '2',
      children: [
        { id: '2-1', children: [] },
        { id: '2-2', children: [] }
      ]
    },
    {
      id: '3',
      children: []
    }
  ]
}
 filterTree(treeData, ['1-1']) will be
 {
    "id": "root",
    "children": [
        {
            "id": "1",
            "children": [
                {
                    "id": "1-1",
                    "children": []
                }
            ]
        }
    ]
}*/

export const filterTree = (root, conditionFn) => {
  const filterFn = node => {
    if (conditionFn(node)) {
      return true
    }
    if (node && node.children && node.children.length > 0) {
      const newChildren = node.children.filter(filterFn)
      if (newChildren.length > 0) {
        node.children = newChildren
        return true
      }
    }
    return false
  }

  if (!conditionFn(root)) {
    return null
  }

  const rootClone = { ...root }
  rootClone.children = rootClone.children.filter(filterFn)

  return rootClone
}

export const filterChildrenAndRoot = (root, idsToRemove,  idField = 'id') => {
  if (idsToRemove.includes(root[idField])) {
    return null
  }
  const filteredChildren = root.children.filter(child => !idsToRemove.includes(child[idField]))
  // main node doesnt make sense if all children were removed
  if (root.children.length && filteredChildren.length === 0) {
    return null
  }

  return {
    ...root,
    children: filteredChildren
  }
}

export const removeDuplicates = trees => {
  const pcIdSeen = new Set()
  const filteredTrees = []

  for (const tree of trees) {
    if (!pcIdSeen.has(tree.pcId)) {
      pcIdSeen.add(tree.pcId)
      const prunedTree = pruneTree(tree, pcIdSeen)
      filteredTrees.push(prunedTree)
    }
  }

  return filteredTrees
}

const pruneTree = (node, pcIdSeen) => {
  const newNode = { ...node, children: [] }

  for (const child of node.children) {
    if (!pcIdSeen.has(child.pcId)) {
      pcIdSeen.add(child.pcId)
      newNode.children.push(pruneTree(child, pcIdSeen))
    }
  }

  return newNode
}

export const traverseAndModify = (node, nodeHandler) => {
  nodeHandler(node)
  if (node && node.children && node.children.length > 0) {
    node.children.forEach(child => {
      traverseAndModify(child, nodeHandler)
    })
  }
}

export const findElementsWithPcId = (input, result = [], parentHasPcId = false) => {
  if (Array.isArray(input)) {
    input.forEach(item => findElementsWithPcId(item, result, parentHasPcId))
  } else if (input && typeof input === 'object') {
    const currentHasPcId = input.hasOwnProperty('pcId') && input.pcId !== null

    if (currentHasPcId && !parentHasPcId) {
      result.push(input)
    }
    if (Array.isArray(input.children)) {
      input.children.forEach(child => findElementsWithPcId(child, result, currentHasPcId))
    }
  }
  return result
}

export const findElementById = (node, id, filedId = 'id') => {
  if (node[filedId] === id) {
    return node
  }

  if (node.children) {
    for (const child of node.children) {
      const result = findElementById(child, id, filedId)
      if (result) {
        return result
      }
    }
  }
}

export const findIdBeforeId = (node, targetId, field = 'id', labelField = 'label') => {
  function traverse(currentNode, targetId, ancestors) {
    if (currentNode && currentNode[field] === targetId) {
      return ancestors
    }

    if (currentNode?.children) {
      for (const child of currentNode.children) {
        const result = traverse(child, targetId, ancestors.concat({
          label: value(currentNode, labelField) || currentNode.label,
          id: currentNode[field]
        }).filter(v => !!v.id))

        if (result) return result
      }
    }
    return null
  }

  return traverse(node, targetId, []) || []
}

export const parseDtcQuery = (dtcString = '') => {
  if (!dtcString) return []

  const dtcParts = dtcString.split(',')

  return dtcParts.map(dtc => {
    const parts = dtc.split('-')
    const obj = {}
    parts.forEach(part => {
      const [key, value] = part.split(':')
      if (key && value) {
        const properKey = key.toLowerCase().trim()
        obj[properKey] = value.trim()
      }
    })
    return obj
  })
}

