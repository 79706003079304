import React, { useState } from 'react'
import { Button } from 'rsuite'
import { useTranslation } from 'react-i18next'
import { isMobile, isTablet } from 'react-device-detect'

import useProductData from '../hooks/useProductData'
import { sortBy, prop, groupBy } from 'ramda'
import styled from "styled-components"
import PlusIcon from "@rsuite/icons/Plus"
import QrIcon from "@rsuite/icons/Qrcode"
import Selector from '../components/selector'
import { toOptions } from '../utils'
import {useDispatch, useSelector} from 'react-redux'
import useDiagnostic from '../hooks/useDiagnostic'
import SelectorList, { SelectData } from "../components/selectorlist"
import SelectorDrawer from "../components/selectordrawer"
import {push} from "redux-first-history";

import { boundingBox, centerText, outline, Scanner, useDevices } from '@yudiel/react-qr-scanner'

const SplashContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background-color: black;
`

const StartButton = styled.div`
  color: black;
  background-color: #00a8a9;
  width: 300px;
  font-size: 1.2rem;
  padding: 10px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-top: 50px;
  ${props => props.disabled && `
    background-color: #cccccc;
    cursor: not-allowed;
    opacity: 0.6;
  `}

`

const ImageBackground = styled.div`
  background-image: url("/kubotaBackground4.jpg");
  height: 70vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 600px;
`

const ButtonHolder = styled.div`
  margin: -18px auto 0 auto;
  text-align: center;
`

const SeriesButton = styled(Button)`
  border-radius: 0;
  font-weight: bold;
  width: 80px;
  border: 1px solid gray;
  &.rs-btn-active {
    background-color: #fd6103;
    color: white;
    border: 1px solid #fd6103;
  }
`

const NotesSection = styled.div`
  font-size: 18px;
  color: white;
  margin: 30px auto 0 auto;
  width: 50vw;
`

const InputsBlock = styled.div`
  position: absolute;
  width: 300px;
  top: 350px;
  transform: ${({ showScanner }) => showScanner ? 'translate(-50%, -80%)' : 'translate(-50%, -50%)'};
  left: 50%;
  
  @media (max-width: 700px) and (orientation: portrait) {
    top: 310px;
    zoom: 0.9;
  }
`;


const mock = {
  m5: {
    notes: 'test m5 update notes Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo'
  },
  m6: {
    notes: 'test2 m6 update notes Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo'
  },
  m7: {
    notes: 'test3 m7 update notes Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo'
  }
}


const Splash = () => {
  const { t } = useTranslation()
  const [activeSeries, setActiveSeries] = useState('all')
  const [showMoreStates, setShowMoreStates] = useState(
    Object.keys(mock).reduce((acc, series) => ({ ...acc, [series]: false }), {})
  )
  const dispatch = useDispatch()
  const { selectedSeries, selectedSSDBSeries, selectedProduct, selectedSerialNumber, selectedPin, setSelector }: any = useDiagnostic()
  const user = useSelector(state => state.user?.currentUser)
  const pins = useSelector(state => state.application?.pins)
  const [openDrawer, setOpenDrawer] = useState(false)
  const [activeSelector, setActiveSelector] = useState('')
  const [drawerData, setDrawerData] = useState<SelectData[]> ([])

  const [showScanner, setShowScanner] = useState(false)

  const { getDataByKey } = useProductData()
  const series = getDataByKey('series')
  const updates =
    sortBy(
      prop('createdAt'), getDataByKey('version_update')
    )
    .filter(_ => !!_.published)
    .map(_ => ({ seriesName: series.find(s => _.seriesId === s.id )?.name, ..._}))
  const udpatesGroupped = groupBy(prop('seriesName'), updates)


  const showMoreLength = 70

  const onOpenSelector = (selectorName: string) => {
    console.log('onOpenSelector :: ', selectorName, selectedSeries)
    let options = []
    let drawerData = getDataByKey(selectorName, selectedSeries?.label)
    console.log('drawerData', drawerData)

    if (selectorName === 'product_group') {
      try {
        // Filter product groups by mapping groups linked to current series
        console.log('selectedSeries', selectedSeries)
        console.log('selected SSDB series', selectedSSDBSeries)
        console.log('ssdb_series', getDataByKey('_s_s_d_b_series'))
        console.log('series', getDataByKey('series'))
        console.log('salesCompanies', getDataByKey('sales_company'))
        console.log('map_ps_sc', getDataByKey('map_ps_sc'))
        console.log('map_ps_pg', getDataByKey('map_ps_pg'))
        const mappingGroups = getDataByKey('mapping_group', selectedSeries?.label)
        let mappingGroupIds = mappingGroups.map((_: any) => _.id)
        let allowedProductGroupIds: any = []

        console.log('current user', user)
        const salesCompany = getDataByKey('sales_company').find((_: any) => _.name === user.company)
        if (salesCompany) {
          const publishSettingIds = getDataByKey('map_ps_sc').filter((_: any) => _.salesCompanyId === salesCompany.id).map((_: any) => _.publishSettingId)
          allowedProductGroupIds = getDataByKey('map_ps_pg').filter((_: any) => publishSettingIds.includes(_.publishSettingId)).map((_: any) => _.productGroupId)
        }

        // const filteredMappingGroupIds = mappingGroupIds.filter((_: any) => allowedMappingGroupIds.includes(_))

        console.log('mappingGroupIds', mappingGroupIds)
        console.log('allowedProductGroupIds', allowedProductGroupIds)
        console.log('drawerData', drawerData)

        console.log('onOpenSelector', selectorName, drawerData, selectedSeries, mappingGroups)
        // drawerData = drawerData.filter((_: any) => mappingGroupIds.includes(_.mappingGroupId ))
        // drawerData = drawerData.filter((_: any) => allowedProductGroupIds.includes(_.id) && mappingGroupIds.includes(_.mappingGroupId ))
        drawerData = drawerData.filter((_: any) => allowedProductGroupIds.includes(_.id) && _.ssdbSeriesId === selectedSSDBSeries.item.ssdbId)

        drawerData.sort((a: any, b: any) => a.name.localeCompare(b.name)) // KID-107
      } catch (e) {
        console.error(e)
        drawerData = []
      }
    }


    if (selectorName === '_s_s_d_b_series') {
      let allowedProductGroupIds: any = []
      console.log('current user', user)
      const salesCompany = getDataByKey('sales_company').find((_: any) => _.name === user.company)
      if (salesCompany) {
        const publishSettingIds = getDataByKey('map_ps_sc').filter((_: any) => _.salesCompanyId === salesCompany.id).map((_: any) => _.publishSettingId)
        allowedProductGroupIds = getDataByKey('map_ps_pg').filter((_: any) => publishSettingIds.includes(_.publishSettingId)).map((_: any) => _.productGroupId)
      }
      console.log('allowedProductGroupIds', allowedProductGroupIds)
      console.log('ssdb series', getDataByKey('_s_s_d_b_series'))
      console.log('product groups', getDataByKey('product_group'))

      const allOptions: any = toOptions(drawerData, 'name', 'seriesId').filter(({ value }) => !!value)
      allOptions.sort((a: any, b: any) => a.label.localeCompare(b.label)) // KID-107

      options = allOptions.filter((_: any) => {
        console.log('option', _)
        const currentProductGroups = getDataByKey('product_group').filter((__: any) => __.ssdbSeriesId === _.item.ssdbId && allowedProductGroupIds.includes(__.id))
        console.log('currentProductGroups', currentProductGroups)
        return currentProductGroups.length > 0
      })
    } else {
      options = toOptions(drawerData, 'name', 'id')
    }

    console.log('options', options)

    setActiveSelector(selectorName)
    setDrawerData(options)
    setOpenDrawer(true)
  }

  const onSelect = (item: SelectData) => {
    setSelector(activeSelector, item)
    setOpenDrawer(false)
  }

  const onTextInput = (inputName: string, value: string) => {
    console.log('onTextInput', inputName, value)
    setSelector(inputName, value)
  }

  const start = () => {
    if (selectedProduct) dispatch(push('/selectDtc'))
  }

  const toggleShowMore = series => {
    setShowMoreStates(prev => ({ ...prev, [series]: !prev[series] }))
  }

  const notesComponent = (series, notes) => (
    <>
      <div dangerouslySetInnerHTML={{ __html: showMoreStates[series] ? notes : `${notes.substring(0, showMoreLength)}${notes.length > showMoreLength ? '...' : ''}` }} />
      <Button
        style={{ display: notes.length > showMoreLength ? 'inline' : 'none' }}
        appearance='link'
        onClick={() => toggleShowMore(series)}
      >
        {showMoreStates[series] ? t('Show Less') : t('Show More')}
      </Button>
    </>
  )

  const getNotes = activeSeries => {
    if (activeSeries === 'all') {
      return Object.keys(udpatesGroupped).map((series) => (
        <div key={series}>
          <h5>{series.toUpperCase()}:</h5>
          <div style={{ marginLeft: 5 }}>
            {notesComponent(series, getNotesString(udpatesGroupped[series]))}
          </div>
        </div>
      ))
    } else {
      return (
        <div>
          {notesComponent(activeSeries, getNotesString(udpatesGroupped[activeSeries]))}
        </div>
      )
    }
  }

  const getNotesString = updatesArray =>
    updatesArray.reduce((acc, update) => {
      acc += '   ' + update.notes.replace(/\n/g, '<br />')

      return acc
    }, '')

  const [deviceId, setDeviceId] = useState(undefined)
  const [tracker, setTracker] = useState('centerText')

  const [pause, setPause] = useState(false);

  const devices = useDevices();

  function getTracker() {
      switch (tracker) {
          case 'outline':
              return outline;
          case 'boundingBox':
              return boundingBox;
          case 'centerText':
              return centerText;
          default:
              return undefined;
      }
  }

  return (
    <SplashContainer className='intro_screen'>
      <InputsBlock showScanner={showScanner}>
        <Selector name='pin' placeholder='pin' text onTextInput={onTextInput} value={selectedPin || ''} field={t('Pin')} />
        {(isMobile || isTablet) && <StartButton onClick={() => setShowScanner(!showScanner)} style={{ marginTop: 10 }}>
          <div>{t('Scan QR Code')}</div>
          <QrIcon/>
        </StartButton>
        }
        {
          showScanner && (
              <Scanner
                  formats={[
                      'qr_code',
                      'micro_qr_code',
                      'rm_qr_code'
                  ]}
                  constraints={{
                    deviceId
                  }}
                  onScan={(detectedCodes) => {
                      // alert(JSON.stringify(detectedCodes, null, 2))
                      if (detectedCodes && detectedCodes.length > 0) {
                        console.log('result', detectedCodes)
                        setSelector('pin', detectedCodes[0].rawValue)
                        setShowScanner(false)
                      }
                  }}
                  onError={(error) => {
                      alert(`onError: ${error}'`);
                  }}
                  components={{
                      audio: true,
                      onOff: true,
                      torch: true,
                      zoom: true,
                      finder: true,
                      tracker: getTracker()
                  }}
                  allowMultiple={true}
                  scanDelay={2000}
                  paused={pause}
              />
          )
        }
        {!showScanner &&
          <>
            <div style={{ textAlign: 'center', marginTop: 10, marginBottom: 5 }}>
              <h4>
                {t`OR`}
              </h4>
            </div>
            <Selector name='_s_s_d_b_series' placeholder='series' onOpenSelector={onOpenSelector} value={selectedSSDBSeries?.label || ''} field={t('Select Series')}/>
            <Selector name='product_group' placeholder='product' disabled={!selectedSSDBSeries} onOpenSelector={onOpenSelector} value={selectedProduct?.label || ''} field={t('Select Product')} />
            <Selector name='serialNumber' placeholder='serial number' disabled={!selectedSSDBSeries}  text onTextInput={onTextInput} value={selectedSerialNumber || ''} field={t('Serial Number')} />
            <StartButton onClick={start} disabled={!selectedProduct}>
            <div>{t('Start Diagnosis')}</div>
            <PlusIcon />
            </StartButton>
          </>
        }
      </InputsBlock>
      <SelectorDrawer isOpen={openDrawer} onClose={() => setOpenDrawer(false)} body={<SelectorList data={drawerData} withSublabel={false} onRowClick={onSelect} />} />
      <div style={{ backgroundColor: '#00a8a9', textAlign: 'center', padding: 10 }}>
        <img src='/kubotaLogo.png' style={{ height: 40 }}/>
      </div>
      <ImageBackground />
      <ButtonHolder>
        <SeriesButton onClick={() => setActiveSeries('all')} active={activeSeries === 'all'}>All</SeriesButton>
        {
          Object.keys(udpatesGroupped).map(series =>
            <SeriesButton onClick={() => setActiveSeries(series)} active={activeSeries === series} key={series}>{series.toUpperCase()}</SeriesButton>
          )
        }
      </ButtonHolder>
      <NotesSection>
        {!!updates.length && <h4>{t('Update')}:</h4>}
        <div style={{ marginLeft: 20 }}>
          {getNotes(activeSeries)}
        </div>
      </NotesSection>
    </SplashContainer>
  )
}

export default Splash
