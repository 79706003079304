import { createSlice } from '@reduxjs/toolkit'
import { RootState } from './store'

import * as R from 'ramda'

export interface ApplicationState {
  connectionStatus: 'online' | 'offline',
  queryParams: {},
  configs: []
  dtcModalIsOpened: {}
}

const initialState: ApplicationState = {
  connectionStatus: 'offline',
  queryParams: {},
  configs: [],
  publications: [],
  dtcModal: {
    isOpened: false,
    dtcId: null
  },
  lang: 'eng',
  sortWeights: {},
  translations: {},
  signalMapping: [],
  modelCodeProductGroupMapping: [],
  kobdMessagesDebug: [],
  pins: []
}

export const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setConnectionStatus: (state, action) => {
      state.connectionStatus = action.payload
    },
    setQueryParams: (state, action) => {
      state.queryParams = action.payload
    },
    setConfigs: (state, action) => {
      for (const config of action.payload) {
        if (config.key === 'signalMapping') {
          state.signalMapping = JSON.parse(config.value)
        }
        if (config.key === 'modelCodeProductGroupMapping') {
          state.modelCodeProductGroupMapping = JSON.parse(config.value)
        }
      }
      state.configs = action.payload
    },
    setPublications: (state, action) => {
      state.publications = action.payload
    },
    setSortWeights: (state, { payload }) => {
      state.sortWeights = payload
    },
    openDTCModal:  (state,{ payload }) => {
      state.dtcModal.isOpened = true
      state.dtcModal.dtcId = payload
      console.log('openDTCModal', payload)
    },
    closeDTCModal:  (state) => {
      state.dtcModal.isOpened = false
      state.dtcModal.dtcId = null
    },
    setTranslations: (state, { payload }) => {
      state.translations = payload
    },
    setSignalMapping: (state, { payload }) => {
      state.signalMapping = payload
    },
    setModelCodeProductGroupMapping: (state, { payload }) => {
      state.modelCodeProductGroupMapping = payload
    },
    setKobdMessagesDebug: (state, { payload }) => {
      state.kobdMessagesDebug.push(payload)
      state.kobdMessagesDebug = R.uniqBy(R.prop('Function'), state.kobdMessagesDebug)

      if (state.kobdMessagesDebug.length > 10) {
        state.kobdMessagesDebug = state.kobdMessagesDebug.slice(-10)
      }
    },
    setPins: (state, action) => {
      state.pins = action.payload
    },
    setSignalMapping: (state, action) => {
      state.signalMapping = action.payload
    },
  }
})


export const {
  setConnectionStatus, setQueryParams, setConfigs, setKobdMessagesDebug,
  setPublications, setSortWeights, openDTCModal, closeDTCModal, setTranslations, setPins, setSignalMapping
} = applicationSlice.actions

export const selectConnectionStatus = (state: RootState) => state.application.connectionStatus
export const selectQueryParams = (state: RootState) => state.application.queryParams
export const selectConfigs = (state: RootState) => state.application.configs
export const selectPublications = (state: RootState) => state.application.publications
export const selectSignalMapping = (state: RootState) => state.application.signalMapping
export const selectModelCodeProductGroupMapping = (state: RootState) => state.application.signalMapping

export default applicationSlice.reducer
