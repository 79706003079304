import React from 'react'

const PCListItem = ({ value, label, subLabel, onRowClick, isActive = false, disabled = false, gray = false }: any) =>
  <div
    className={`pc-list-item ${isActive && 'pc-list-item-active' || ''}`}
    onClick={() => !disabled && onRowClick({ label, value })}
    style={{ cursor: disabled ? 'default' : 'pointer' }}
  >
    <h6 style={{ color: (disabled || gray)  ? 'gray' : 'black' }} dangerouslySetInnerHTML={{ __html: label }} />
    <div style={{ color: (disabled || gray) ? 'gray' : 'black' }}>{subLabel}</div>
  </div>

export default PCListItem
