import styled from 'styled-components'
import Resizer from 'react-image-file-resizer'
import {Col, Row, Panel, Divider, Loader, FlexboxGrid} from 'rsuite'
import { pluck } from "ramda";
import { Carousel } from 'react-responsive-carousel'
import CachedImage from '../cachedImage'
import { image, imageWithoutToken, ssdbImage } from '../../utils/fetcher'
import diagramImg from '../../images/diagram.png'
import { FaCamera } from "react-icons/fa";
import ListIcon from '@rsuite/icons/List'
import {useTranslation} from 'react-i18next'
import InlineModal from '../inlineModal'
import {BlueGhostButton, GreenGhostButton, RedGhostButton, SecondaryButton} from '../button'
import {DRAWER_BODY, localImagesCache, TEST_RESULTS} from '../../utils/constants'
import {useDispatch, useSelector} from 'react-redux'
import SelectorList from '../selectorlist'
import {openDTCModal} from '../../store/application'
import DiagnosticItemList from '../diagnosticItemList'
import PCList from '../pcList'
import React, {useEffect,  useState} from 'react'
import SSDBTable from "../ssdbTable";
import FileDownloadIcon from "@rsuite/icons/FileUpload";
import {sendPhoto, getPhotos} from "../../utils/api";
import useDiagnostic from "../../hooks/useDiagnostic";
import {setTestPhotos} from "../../store/diagnostic";
import { getDataByKey } from '../../hooks/useProductData'
import WsmLinkViewer from '../wsmLinkViewer'
import PdfReport from "../pdfReport";
import PhotosRowSlider from "../PhotosRowSlider";
import DataMonitor from "../dataMonitor";
import LaunchKobd from "../launchKobd";

export const ReferenceValues = styled.div`
  border-radius: 5px;
  margin: 30px 0;
  background: #F7F8FA;
  padding: 15px;
`

export const ValueTypeSelector = styled.div<{isActive?: boolean, first?: boolean, last?: boolean, value?: string, disabled?: boolean }>`
  padding: 7px 0;
  cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
  background: ${({ isActive }) => isActive ? '#CBF4F4' : '#F7F8FA'};
  text-align: center;
  border: 1px solid ${({ isActive }) => isActive ? '#009999' : '#E0E0E0'};
  border-top-left-radius: ${({ first }) => first ? '5px' : '0'};
  border-bottom-left-radius: ${({ first }) => first ? '5px' : '0'};
  border-top-right-radius: ${({ last }) => last ? '5px' : '0'};
  border-bottom-right-radius: ${({ last }) => last ? '5px' : '0'};
`

export const LinkButton = styled.div<{disabled?: boolean}>`
  font-weight: bold;
  color: #1FA5A5;
  filter: ${({ disabled }) => disabled ? 'grayscale(1)' : 'grayscale(0)'};
  padding: 12px 20px;
  text-align: center;
  cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
  background: #F7F8FA;
  border: 1px solid #E0E0E0;
  border-radius: 5px;
`

const resizeFile = file => new Promise(resolve =>
  Resizer.imageFileResizer(
    file,
    1600,
    1600,
    'JPEG',
    80,
    0,
    (uri) => {
      resolve(uri)
    },
    'file',
    200,
    200,
  ))

export const LinksBlock = ({ pcId, ssdbInfo, link = {}, pcDetail }) => {
  const isOnline = useSelector(state => state.application.connectionStatus) === 'online'
  const { geTestPhotos, selectedProduct } = useDiagnostic()
  const testPhotos = geTestPhotos(pcId)
  const [images, setImages] = useState([])
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const handleImageChange = async (event) => {
    if (event.target.files) {
      const imagePostFixName = Date.now()
      const files = Array.from(event.target.files)
      const newImages = await Promise.all(files.map(file => uploadImage(file, imagePostFixName)))
      dispatch(setTestPhotos({ pcId, photos: [...testPhotos, ...newImages] }))
    }
  }

  const uploadImage = async (file, imagePostFixName) => {
    setLoading(true)
    const formData = new FormData()
    const resizedImage = await resizeFile(file)
    const fileName = resizedImage.name
    const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2)
    const fileNameWithoutExtension = fileName.replace(/\.[^/.]+$/, "")
    const newName = `${fileNameWithoutExtension}_${imagePostFixName}.${fileExtension}`

    formData.append('image', resizedImage)
    formData.append('name', newName)

    try {
      if (isOnline) {
        await sendPhoto(pcId, formData)
      } else {
        const cacheImage = await caches.open(localImagesCache)
        const cacheKey = `${pcId}/${newName}`
        console.log('!isOnline uploadImage', cacheKey, resizedImage)
        const fileResponse = new Response(resizedImage, { headers: { 'Content-Type': resizedImage.type } })
        await cacheImage.put(cacheKey, fileResponse)
      }

      return newName
    } catch (error) {
      console.error('Error uploading the image:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleCrossClick = name => {
    dispatch(setTestPhotos({ pcId, photos: testPhotos.filter(p => p !== name)}))
  }

  const onClickLink = (link: string) => {
    if (!link) return
    window.open(link, '_blank').focus()
  }

  useEffect(() => {
    const fetchImages = async () => {
      setLoading(true)

      let imageBlobsRemote = []

      try {
        if (isOnline && testPhotos?.length) {
          const response = await getPhotos(pcId, testPhotos)
          imageBlobsRemote = response.map((bufferObj, i) => {
            if (bufferObj) {
              const uint8Array = new Uint8Array(bufferObj.data)
              return { name: testPhotos[i], blob: new Blob([uint8Array], { type: 'image/jpeg' })}
            } else {
              return null
            }
          })
        }
        const cache = await caches.open(localImagesCache)
        const imageBlobsLocal = await Promise.all(testPhotos.map(async (photo, i) => {
          const cacheKey = `${pcId}/${photo}`
          const cachedResponse = await cache.match(cacheKey)
          console.log('!isOnline cache cache cache', cacheKey, photo)
          if (cachedResponse) {
            return { name: testPhotos[i], blob: await cachedResponse.blob() }
          }
          return null
        }))
        console.log('imageBlobsRemote', imageBlobsRemote)
        console.log('imageBlobsLocal', imageBlobsLocal)
        const objectURLs = [
          ...imageBlobsRemote.filter(b => !!b),
          ...imageBlobsLocal.filter(b => !!b)
        ].map(p => ({ name: p.name, objectUrl: URL.createObjectURL(p.blob) }))

        setImages(prevImages => [...prevImages, ...objectURLs])
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    }

    fetchImages()

    return () => {
      setImages([])
    }
  }, [JSON.stringify(testPhotos), isOnline])

  let webCircuitDiagramLink = selectedProduct?.item?.webCircuitDiagramLink
  if (ssdbInfo?.parts_id && ssdbInfo?.parts_id.length > 0) {
    webCircuitDiagramLink += `&part=${ssdbInfo?.parts_id}`
  }
  const hasWebCircuitDiagramLink = selectedProduct?.item?.webCircuitDiagramLink && webCircuitDiagramLink && webCircuitDiagramLink.length > 0

  const wsmLinks = getDataByKey('wsm_link') || []
  const wsmLink = wsmLinks.find((_: any) => _.id === selectedProduct?.item?.wsmLinkId)
  const wsmLinkNumber = wsmLink?.number

  let wsmGuids: any = []

  if (ssdbInfo && ssdbInfo.wsm_guids && ssdbInfo.wsm_guids.length > 0 ) wsmGuids = ssdbInfo.wsm_guids.split(',') // .split(/\r?\n/).split(',')
  wsmGuids = wsmGuids.map(_ => _.trim())

  const hasWsmLink = wsmLinkNumber && wsmLinkNumber.length > 0 && wsmGuids.length > 0
  const hasAr = pcDetail.arLink && ssdbInfo?.['3d_ar_link']

  return (
    <div>
      { /* <pre>{JSON.stringify(webCircuitDiagramLink, null, 2)}</pre> */ }
      { /* <pre>{JSON.stringify(ssdbInfo?.wsm_guids?.split(/\r?\n/), null, 2)}</pre>
      <pre>{JSON.stringify(selectedProduct?.item?.wsmLinkId, null, 2)}</pre> */ }
      <FlexboxGrid justify="space-around" align="top" style={{ marginTop: '10px' }}>
        {
          pcDetail.wsm && (
            <FlexboxGrid.Item colspan={5}>
              <WsmLinkViewer disabled={!hasWsmLink} ssdbInfo={ssdbInfo} />
            </FlexboxGrid.Item>
          )
        }
        {
          pcDetail.circuit && (
            <FlexboxGrid.Item colspan={5}>
              <LinkButton onClick={() => hasWebCircuitDiagramLink && onClickLink(webCircuitDiagramLink)} disabled={!hasWebCircuitDiagramLink}
                          style={{ padding: '10px 20px' }}>
                <img style={{ height: '25px', width: '25px' }} src={diagramImg}/>
              </LinkButton>
            </FlexboxGrid.Item>
          )
        }
        <FlexboxGrid.Item colspan={5}>
          <input
            type="file"
            accept="image/*"
            capture="environment"
            onChange={handleImageChange}
            style={{ display: 'none' }}
            id="imageInput"
          />
          <label htmlFor="imageInput">
            <LinkButton style={{ padding: '10px 20px' }} disabled={loading}>
              <FaCamera size={20} />
            </LinkButton>
          </label>
        </FlexboxGrid.Item>
        {hasAr && <FlexboxGrid.Item colspan={5}>
          <LinkButton
            disabled={!hasAr}
            style={{ padding: '12px 20px' }}
            onClick={() => window.open(ssdbInfo?.['3d_ar_link'], '_blank')}
          >
            Others
          </LinkButton>
        </FlexboxGrid.Item>
        }
      </FlexboxGrid>
      <div style={{ textAlign: 'center', margin: '10px 0' }}>
        <PhotosRowSlider images={pluck('objectUrl', images)} loading={loading} entities={images} onRemove={img => handleCrossClick(img.name)} />
      </div>
    </div>
  )
}

const RealCases = ({ grsImg }) => {
  const { t } = useTranslation()

  return (
    <div>
      <h6>{t`Real case examples`}</h6>
      <br />
      <PhotosRowSlider images={grsImg} imageHandler={image} />
    </div>
  )
}

export const PcListMobileLink = ({ onClick }) =>
  <div onClick={onClick} style={{ cursor: 'pointer', fontSize: '16px', height: '20px', display: 'inline-block'}}>
    <ListIcon fill='#009999' />
    <span style={{ marginLeft: '11px' }}>Possible Causes</span>
  </div>

export const AskDrawerBody = ({ testData, onAnswer, setOpen, resolveRemainsDTCs, setDrawerBody }) => {
  const { t } = useTranslation()
  return (
    <InlineModal
      header={t`After repair, have all DTCs been cleared?`}
      buttons={[
        <GreenGhostButton
          onClick={() => {
            resolveRemainsDTCs()
            setOpen(false)
          }}
        >
          {t('Yes')}
        </GreenGhostButton>,
        <RedGhostButton
          onClick={() => {
            onAnswer(TEST_RESULTS.FAIL, testData?.[0])
            setOpen(false)
          }}
          style={{ margin: '20px 0' }}
        >
          {t('Not at All')}
        </RedGhostButton>,
        <BlueGhostButton onClick={() => setDrawerBody(DRAWER_BODY.PARTIALLY)}>
          {t('Partially Cleared')}
        </BlueGhostButton>
      ]}
    />
  )
}

export const PartiallyDrawerBody = ({ notResolvedDiagnosticItems, onResolveDiagnosticItem, onAddToList, unusedDiagnosticItems }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  return (
    <InlineModal
      style={{ height: '100%' }}
      header={t('DTC partially cleared')}
      text={t('Your DTCs have been partially cleared. Please remove the resolved DTCs manually')}
      additional={
        <>
          <div style={{ height: '45%', overflow: 'auto' }}>
            <SelectorList
              withSearch={false}
              data={notResolvedDiagnosticItems}
              onActionBtnClick={onResolveDiagnosticItem}
              onLabelClick={({ value }) => dispatch(openDTCModal(value))}
              actionText={t('Resolve')}
            />
          </div>
          <br />
          <h6>{t('Add new DTCs if any')}</h6>
          <br />
          <div style={{ height: '45%', overflow: 'auto' }}>
            <DiagnosticItemList
              data={unusedDiagnosticItems}
              onLabelClick={({ value }) => dispatch(openDTCModal(value))}
              onActionBtnClick={onAddToList}
            />
            {/*<SelectorList*/}
            {/*  withSearch*/}
            {/*  data={getUnusedDTCs()}*/}
            {/*  onActionBtnClick={onAddToList}*/}
            {/*  onLabelClick={({ value }) => dispatch(openDTCModal(value))}*/}
            {/*  actionText={t('Add')}*/}
            {/*/>*/}
          </div>
        </>
      }
    />
  )
}

export const TestNoAnswerDrawerBody = ({ goToRepairTabWithoutTest, setOpen }) => {
  const { t } = useTranslation()
  return (
    <InlineModal
      header={t('Test is not passed')}
      text={t('Test has not been  passed, go to repair tab anyway?')}
      buttons={[
        <GreenGhostButton style={{ marginTop: '20px' }} onClick={() => goToRepairTabWithoutTest()}>{t('Yes')}</GreenGhostButton>,
        <RedGhostButton style={{ marginTop: '20px' }} onClick={() => setOpen(false)}>{t('No')}</RedGhostButton>
      ]}
    />
  )
}

export const TestNoProblemDrawerBody = ({ setOpen, setOpenedTestRepairTab }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  return (
    <InlineModal
      header={t('Are You sure?')}
      text={t('There was no problem in the test, go to repair tab anyway?')}
      buttons={[
        <GreenGhostButton style={{ marginTop: '20px' }} onClick={() => {
          dispatch(setOpenedTestRepairTab('repair'))
          setOpen(false)
        }}>{t('Yes')}</GreenGhostButton>,
        <RedGhostButton style={{ marginTop: '20px' }} onClick={() => setOpen(false)}>{t('Cancel')}</RedGhostButton>
      ]} />
  )
}

export const PcListDrawerBody = ({ keysToExpand, openedPC, pcBySelectedSeriesAndDTCs, onOpenPC, setKeysToExpand = () => null, disabled }) =>
  <PCList
    disabled={disabled}
    keysToExpand={keysToExpand}
    onExpand={expandedKeys => setKeysToExpand(expandedKeys)}
    onOpenPC={onOpenPC}
    openedPC={openedPC}
    pcBySelectedSeriesAndDTCs={pcBySelectedSeriesAndDTCs}
  />

export const PcListDrawerFooter = ({ exportDiagnosticJSON, kitsUrl, onInquiryClick }) =>
  <FlexboxGrid style={{ flexDirection: 'column', marginBottom: 20, background: 'white' }} align='middle'>
    <FlexboxGrid.Item>
      <PdfReport showPreview={false} withButton/>
    </FlexboxGrid.Item>
    <br/>
    <FlexboxGrid.Item>
      <SecondaryButton onClick={exportDiagnosticJSON}>
        Export project file<FileDownloadIcon/>
      </SecondaryButton>
    </FlexboxGrid.Item>
    <br/>
    <FlexboxGrid.Item>
      <a href={kitsUrl} target='_blank' rel='noreferrer'>
        <SecondaryButton onClick={onInquiryClick}>Create an inquiry</SecondaryButton>
      </a>
    </FlexboxGrid.Item>
  </FlexboxGrid>

export const DrawerBodyContent = ({ drawerBody, ...otherProps }) => {
  switch (drawerBody) {
    case DRAWER_BODY.ASK:
      return <AskDrawerBody {...otherProps} />
    case DRAWER_BODY.PARTIALLY:
      return <PartiallyDrawerBody {...otherProps} />
    case DRAWER_BODY.TEST_NO_ANSWER:
      return <TestNoAnswerDrawerBody {...otherProps} />
    case DRAWER_BODY.TEST_NO_PROBLEM:
      return <TestNoProblemDrawerBody {...otherProps} />
    case DRAWER_BODY.PC_LIST:
      return <PcListDrawerBody {...otherProps} />
    default:
      return null
  }
}

export const DrawerFooterContent = ({ drawerBody, ...otherProps }) => {
  switch (drawerBody) {
    case DRAWER_BODY.PC_LIST:
      return <PcListDrawerFooter {...otherProps} />
    default:
      return null
  }
}

const ImageCarousel = ({ linksImg, ssdbInfoImage }) => {
  /** conditional rendering doesnt work correct, it creates an empty extra image */

  if (linksImg.length && !ssdbInfoImage) {
    return (
      <Carousel className='image-carousel' autoPlay infiniteLoop stopOnHover showArrows showStatus showIndicators showThumbs={false}>
        {linksImg?.map((lm, index) => (
          <div key={`link-img-${index}`}>
            <CachedImage src={image(lm)} url={imageWithoutToken(lm)}/>
          </div>
        ))}
      </Carousel>
    )
  }

  if (linksImg.length && ssdbInfoImage) {
    return (
      <Carousel className='image-carousel' autoPlay infiniteLoop stopOnHover showArrows showStatus showIndicators showThumbs={false}>
        {linksImg?.map((lm, index) => (
          <div key={`link-img-${index}`}>
            <CachedImage src={image(lm)} url={imageWithoutToken(lm)}/>
          </div>
        ))}
        <div key={`ssdb-info-img`}>
          { /* <img src={`data:image/png;base64, ${ssdbInfoImage}`}/> */ }
            <img alt='' src={`${ssdbImage(ssdbInfoImage)}`}/>
        </div>
      </Carousel>
    )
  }

  if (!linksImg.length && ssdbInfoImage) {
    return (
      <Carousel className='image-carousel' autoPlay infiniteLoop stopOnHover showArrows showStatus showIndicators showThumbs={false}>
        {ssdbInfoImage && (
          <div key={`ssdb-info-img`}>
            { /* <img src={`data:image/png;base64, ${ssdbInfoImage}`}/> */ }
            <img alt='' src={`${ssdbImage(ssdbInfoImage)}`}/>
          </div>
        )}
      </Carousel>
    )
  }

  return null
}

const AdditionalInfo = ({ additionalInfo }) => {
  const { t } = useTranslation()
  return (
    <div style={{ backgroundColor: '#0000000a', borderRadius: 5, padding: 10 }}>
      <h6>{t`Additional Information`}</h6>
      <br/>
      <div style={{ fontSize: '16px' }} dangerouslySetInnerHTML={{ __html: additionalInfo }}/>
    </div>
  );
}

const RenderPanel = ({ ssdbInfo, additionalInfo, linksImg, grsImg, testId, pcId, pcDetail, signals }) => (
  <Panel style={{ paddingBottom: 16 }}>
    <div style={{ textAlign: 'center' }}>{ssdbInfo?.serviceSpecificationName?.name && <h5>{ssdbInfo?.serviceSpecificationName?.name}</h5>}</div>
    <br />
    { /* <pre>{JSON.stringify(pcDetail, null, 2)}</pre> */ }
    <ImageCarousel linksImg={linksImg} ssdbInfoImage={pcDetail?.partsLocation && ssdbInfo?.partsLocation?.name} />
    {!!grsImg?.length &&
      <>
        <Divider />
        <RealCases grsImg={grsImg} />
      </>
    }
    {ssdbInfo &&
      <>
        <Divider />
        <SSDBTable ssdbInfo={ssdbInfo} testId={testId} pcDetail={pcDetail} />
      </>
    }
    {additionalInfo &&
      <>
        <Divider />
        <AdditionalInfo additionalInfo={additionalInfo} />
      </>
    }
    <LinksBlock pcId={pcId} ssdbInfo={ssdbInfo} pcDetail={pcDetail} />
    {
      (signals.dataMonitor.length > 0) && <DataMonitor signals={signals.dataMonitor} />
    }
    {
     <LaunchKobd signals={signals.launch} />
    }
  </Panel>
)

export const RenderDataInfo = ({ ssdbInfo, additionalInfo, linksImg, grsImg, testId, pcId, pcDetail, signals }) => {
  if (ssdbInfo?.length > 1) {
    return (
      <div>
        <Carousel infiniteLoop showArrows showStatus showIndicators={false} showThumbs={false} className='ssdb-carousel'>
          {ssdbInfo.map((info) => (
            <RenderPanel
              key={`panel-${info.id}`}
              ssdbInfo={info}
              additionalInfo={additionalInfo}
              linksImg={linksImg}
              grsImg={grsImg}
              testId={testId}
              pcId={pcId}
              pcDetail={pcDetail}
              signals={signals}
            />
          ))}
        </Carousel>
      </div>
    )
  }

  if (ssdbInfo?.length === 1) {
    return <RenderPanel
      ssdbInfo={ssdbInfo[0]}
      additionalInfo={additionalInfo}
      linksImg={linksImg}
      grsImg={grsImg}
      testId={testId}
      pcId={pcId}
      pcDetail={pcDetail}
      signals={signals}
    />
  }

  if (ssdbInfo?.length === 0) {
    return <RenderPanel
      linksImg={linksImg}
      grsImg={grsImg}
      additionalInfo={additionalInfo}
      testId={testId}
      pcId={pcId}
      pcDetail={pcDetail}
      signals={signals}
    />
  }

  return null
}

